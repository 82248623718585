import React, { useState, useEffect } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
const Pagepreloader = (props) => {
    return (
        <div class="row w-100 m-0">
            <div class="col-lg-12 text-center">
                <Loader
                    type="Oval"
                    color="#343a40"
                    height={70}
                    width={70}
                    timeout={100000}
                    //3 secs
                />
            </div>
        </div>
    );
};

export default Pagepreloader;
