import axios from 'axios';
import { serverbaselink } from '../../Env_Variables';
const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export const fetchuseauthorization = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/checkauth',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const logincheck = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/logincheck',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const logout = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/generalall/logout',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
