import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { Loggedincontext } from './Loggedincontext.js';
import { Routedispatcherprovider } from './Routedispatcher';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './trans.css';
import Welcome from './components/Welcomefiles/Welcome';
import { fetchuseauthorization } from './components/API/Login_API';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import tabexpreload from './components/Dashboard/Generalfiles/images/tabexpreload.mp4';
import Login from './components/Dashboard/Login/Login';
const Dashboardrouter = React.lazy(() => import('./components/Dashboard/Dashboardrouter'));

const App = (props) => {
    const [nointernetconnection, setnointernetconnection] = useState(false);
    window.addEventListener('offline', function (e) {
        setnointernetconnection(true);
    });

    window.addEventListener('online', function (e) {
        setnointernetconnection(false);
    });
    let history = useHistory();
    const { userloggedinfobjcontext, setuserloggedinfobjcontext, isloggedincontext, setisloggedincontext, useraccesspagescontext, setuseraccesspagescontext } = React.useContext(Loggedincontext);
    const fetchuseauthorizationQuery = useQuery(['fetchuseauthorization'], () => fetchuseauthorization(), { keepPreviousData: true, staleTime: 500000000000000000 });
    // useEffect(() => {
    // }, []);
    useEffect(() => {
        if (fetchuseauthorizationQuery.isSuccess) {
            setisloggedincontext(fetchuseauthorizationQuery.data.data.loggedin);
            if (fetchuseauthorizationQuery.data.data.loggedin) {
                var tempuserloggedinfo = { ...userloggedinfobjcontext };
                tempuserloggedinfo.userinfo = { ...fetchuseauthorizationQuery.data.data.userinfo };
                tempuserloggedinfo.lastquantitychecker = fetchuseauthorizationQuery.data.data.lastquantitychecker;
                tempuserloggedinfo.nextquantitychecker = fetchuseauthorizationQuery.data.data.nextquantitychecker;
                setuserloggedinfobjcontext({
                    ...tempuserloggedinfo,
                });
                if (Array.isArray(fetchuseauthorizationQuery.data.data.userpagepermissions)) {
                    setuseraccesspagescontext([...fetchuseauthorizationQuery.data.data.userpagepermissions]);
                } else {
                    setuseraccesspagescontext([]);
                }
            } else {
            }
        }
    }, [fetchuseauthorizationQuery.isSuccess]);
    return (
        <Router>
            <Routedispatcherprovider>
                <div style={{ height: '100%', width: '100%' }}>
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    backgroundColor: 'white',
                                }}
                                class="row w-100 m-0 d-flex justify-content-center"
                            >
                                <div class="col-lg-12 d-flex justify-content-center align-items-center">
                                    <img src="https://tejarrtech.com/storage/tejarrtechwhitelogo.png" style={{ width: '300px', height: '300px' }} />
                                </div>
                            </div>
                        }
                    >
                        <Route
                            render={({ location, match }) => {
                                return (
                                    <div>
                                        {nointernetconnection && (
                                            <div
                                                class="w-100 text-center h-100 d-flex mx-auto justify-content-center"
                                                style={{
                                                    backgroundColor: 'white',
                                                    position: 'fixed',
                                                    height: '100%',
                                                    zIndex: 99999999999,
                                                    color: 'red',
                                                    opacity: 0.5,
                                                    alignContent: 'center',
                                                    justifyContent: 'center',
                                                    verticalAlign: 'center',
                                                    fontSize: '25px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                <div class="text-center  mt-auto mb-auto m-auto align-items-center d-flex">
                                                    Trying To Connect To The Internet...
                                                    <CircularProgress color="red" width="30px" height="30px" />
                                                </div>
                                            </div>
                                        )}
                                        <TransitionGroup>
                                            <CSSTransition in={match != null} key={location.key} timeout={300} classNames="page" unmountOnExit>
                                                <Switch key={location.key} location={location}>
                                                    <Route
                                                        exact
                                                        path="/"
                                                        render={() => {
                                                            return <Redirect to="/dashboard" />;
                                                        }}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/dashboard"
                                                        render={() => {
                                                            if (fetchuseauthorizationQuery.isSuccess) {
                                                                if (fetchuseauthorizationQuery.data.data.loggedin == true) {
                                                                    return <Redirect to="/dashboard/institute" />;
                                                                } else {
                                                                    return <Redirect to="/dashboard/login" />;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {fetchuseauthorizationQuery.isSuccess && fetchuseauthorizationQuery.data.data.loggedin && (
                                                        <>
                                                            <Route
                                                                path="/dashboard/institute"
                                                                render={() => {
                                                                    if (fetchuseauthorizationQuery.isSuccess) {
                                                                        if (isloggedincontext == true) {
                                                                            return <Dashboardrouter />;
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <Route
                                                                path="/dashboard/welcome"
                                                                render={(props) => {
                                                                    if (fetchuseauthorizationQuery.isSuccess) {
                                                                        if (isloggedincontext == true) {
                                                                            return <Welcome />;
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                    {fetchuseauthorizationQuery.isSuccess && !fetchuseauthorizationQuery.data.data.loggedin && (
                                                        <Route
                                                            path="/dashboard/login"
                                                            render={(props) => {
                                                                if (fetchuseauthorizationQuery.isSuccess) {
                                                                    if (isloggedincontext == false) {
                                                                        return <Login />;
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </Switch>
                                            </CSSTransition>
                                        </TransitionGroup>
                                    </div>
                                );
                            }}
                        />
                    </Suspense>
                </div>
            </Routedispatcherprovider>
        </Router>
    );
};

export default App;
