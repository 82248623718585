import { BrowserRouter as Link } from 'react-router-dom';
import tabexlogo from '../Dashboard/Generalfiles/images/tabexlogo.png';
import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { LanguageContext } from '../../LanguageContext';
import { IoMdClose } from 'react-icons/io';
import Select from 'react-select';
import generalstyles from '../Dashboard/Generalfiles/CSS_GENERAL/general.module.css';
import welcomestyles from './welcome.module.css';
import formstyles from '../Dashboard/Generalfiles/CSS_GENERAL/form.module.css';
import Header from '../Dashboard/Header/Header';
import { Contexthandlerscontext } from '../../Contexthandlerscontext.js';
import { fetchinstitutes } from '../API/InstDashboard_API';
import { useMutation, useQuery } from 'react-query';
import { Loggedincontext } from '../../Loggedincontext.js';
import { Routedispatcher } from '../../Routedispatcher.js';
import { FaBuilding } from 'react-icons/fa';
import styles from '../Dashboard/Generalfiles/CSS_GENERAL/general.module.css';
import Pagepreloader from '../Dashboard/Pagepreloader';

const welcomeselectstyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #e6e6e6',
        backgroundColor: state.isSelected ? '' : '',
        padding: 5,
        textTransform: 'capitalize',
        color: state.isSelected ? '#eac435' : '#022648',
        fontSize: '15px',
        transition: '.3s',
        '&:hover': {
            color: '#eac435',
            cursor: 'pointer',
        },
    }),
    control: () => ({
        border: '2px solid #e6e6e6',
        boxShadow: '0 0 10px #e6e6e6',
        borderRadius: '30px',
        display: 'flex',
        cursor: 'pointer',
        transition: '.3s',
        '&:hover': {
            boxShadow: '0px 9px 20px rgba(75, 72, 72, 0.3)',
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: '15px',
    }),
};
const welcomeselectstylesTranslated = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #e6e6e6',
        backgroundColor: state.isSelected ? '' : '',
        padding: 5,
        textTransform: 'capitalize',
        color: state.isSelected ? '#eac435' : '#022648',
        textAlign: 'right',
        transition: '.3s',
        '&:hover': {
            color: '#eac435',
            cursor: 'pointer',
        },
    }),
    control: () => ({
        border: '2px solid #e6e6e6',
        boxShadow: '0 0 10px #e6e6e6',
        borderRadius: '30px',
        display: 'flex',
        textAlign: 'right',
        cursor: 'pointer',
        transition: '.3s',
        '&:hover': {
            boxShadow: '0px 9px 20px rgba(75, 72, 72, 0.3)',
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        textAlign: 'right',
    }),
};
const Welcome = (props) => {
    const { lang, langdetect } = React.useContext(LanguageContext);
    const { setisloggedincontext, userloggedinfobjcontext, setuserloggedinfobjcontext } = React.useContext(Loggedincontext);
    const { routedispatchercontext } = React.useContext(Routedispatcher);
    useEffect(() => {
        document.title = 'Your Institutes';
    }, []);
    const { setpagetitle_context } = React.useContext(Contexthandlerscontext);
    setpagetitle_context('Welcome');
    const [showCreateStoreModal, setshowCreateStoreModal] = useState(false);
    const [buttonClicked, setbuttonClicked] = useState(false);
    const FetchUserInstitutes = useQuery(['fetchinstitutes'], () => fetchinstitutes(), { keepPreviousData: true, staleTime: 500000000000000000 });

    return (
        <div class={generalstyles.app_container + ' w-100 '}>
            <div class={langdetect == 'en' ? generalstyles.app_main + ' p-0 ' : generalstyles.app_main + ' p-0 '}>
                <div class={langdetect == 'en' ? generalstyles.app_main_outer + ' p-0 ' : `${generalstyles.app_main_outer} ${generalstyles.app_main_outertranslated}` + ' p-0 '}>
                    <Header />
                    <div class={generalstyles.app_main_inner}>
                        <div class="row m-0">
                            <div class="col-lg-12 pl-1 pr-1">
                                <div class={generalstyles.card + ' h-auto '} style={{ minHeight: '90vh' }}>
                                    <div class="row ml-0 mr-0 w-100 d-flex align-items-center">
                                        <div
                                            class={
                                                langdetect == 'en'
                                                    ? 'col-lg-6 col-md-6 col-sm-10 p-0 d-flex align-items-center'
                                                    : 'col-lg-6 col-md-6 col-sm-10 text-right p-0 d-flex align-items-center'
                                            }
                                        >
                                            <p class={`${generalstyles.cardTitle} ${generalstyles.border_bottom_0}` + ' text-uppercase m-0 p-0 '}>Your Institutes</p>
                                        </div>
                                        <div
                                            class={
                                                langdetect == 'en'
                                                    ? ' col-lg-6 col-md-6 text-right d-lg-block d-md-block d-sm-none p-0 '
                                                    : ' col-lg-6 col-md-6 text-left d-lg-block d-md-block d-sm-none  p-0 '
                                            }
                                        >
                                            <button class={`${generalstyles.btn} ${generalstyles.btn_cardheader}`} onClick={() => setshowCreateStoreModal(true)}>
                                                {lang.createnewinst}
                                            </button>
                                        </div>
                                        <div
                                            class={
                                                langdetect == 'en'
                                                    ? 'col-lg-6 col-md-6 col-sm-2 text-right d-lg-none d-md-none d-sm-block p-0'
                                                    : 'col-lg-6 col-md-6 col-sm-2 text-left d-lg-none d-md-none d-sm-block p-0'
                                            }
                                        >
                                            <button
                                                type="submit"
                                                class={
                                                    langdetect == 'en'
                                                        ? `${generalstyles.btn} ${generalstyles.btn_cardheader} ${generalstyles.btn_icon_resp}` + ' float-right d-flex text-center '
                                                        : `${generalstyles.btn} ${generalstyles.btn_cardheader} ${generalstyles.btn_icon_resp}` + ' float-left d-flex text-center '
                                                }
                                                onClick={() => setshowCreateStoreModal(true)}
                                            >
                                                <i class="bx bx-plus h-100 d-flex align-items-center"></i>
                                            </button>
                                        </div>
                                        <div class="col-lg-12 p-0">
                                            <hr class="mt-2 mb-2" />
                                        </div>
                                    </div>
                                    {FetchUserInstitutes.isLoading && (
                                        <div style={{ height: '400px' }} class="d-flex align-items-center">
                                            <Pagepreloader />
                                        </div>
                                    )}
                                    {FetchUserInstitutes.isSuccess && (
                                        <div class="row ml-0 mr-0 w-100 pl-3 pr-3 pl-sm-2 pr-sm-2 mt-2">
                                            {FetchUserInstitutes.data.data.institutes.length == 0 && (
                                                <div class="d-flex align-items-center w-100" style={{ height: '70vh' }}>
                                                    <div class="col-lg-12 text-center">
                                                        <FaBuilding class={`${styles.notcreated_icon}`} size={40} />
                                                        <p class={`${styles.notcreated_text}`}>{lang.noinstitutesyet}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {FetchUserInstitutes.data.data.institutes.map((item, index) => {
                                                var expiredclass = welcomestyles.welcome_card_expired;
                                                if (item.instactive == 0) {
                                                    expiredclass = welcomestyles.welcome_card_expired;
                                                } else {
                                                    expiredclass = '';
                                                }
                                                return (
                                                    <div class="col-xl-6 col-lg-10 ml-lg-auto mr-lg-auto mb-3 pl-sm-1 pr-sm-1">
                                                        <Link to="/Home">
                                                            <div class={`${generalstyles.card} ${welcomestyles.welcome_card} ${expiredclass}`}>
                                                                <div class="row m-0">
                                                                    <div
                                                                        class={
                                                                            langdetect == 'en'
                                                                                ? 'mt-auto mb-auto col-xl-10 col-lg-10 col-md-10 col-sm-8 p-0'
                                                                                : 'mt-auto text-right mb-auto col-xl-10 col-lg-9 col-md-10 col-sm-8 p-0'
                                                                        }
                                                                    >
                                                                        <p class={`${welcomestyles.welcome_store_name}` + ' mb-0 text-overflow '}>{item.instname}</p>
                                                                        {/* <p class={`${welcomestyles.welcome_store_link}` + ' cursor-pointer mb-1 text-overflow'}>https://tabexshops/storeone</p> */}
                                                                        <p class={`${generalstyles.pill}` + ' bg-focus mb-0 pl-2 pr-2 '} style={{ width: 'fit-content' }}>
                                                                            {/* {lang.freetrial} */}
                                                                            Package
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        class={
                                                                            langdetect == 'en'
                                                                                ? `${welcomestyles.welcome_store_logo}` + ' ml-auto '
                                                                                : `${welcomestyles.welcome_store_logo}` + ' mr-auto '
                                                                        }
                                                                    >
                                                                        <img src={tabexlogo} class="" alt="Store Logo" />
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex text-center align-items-center justify-content-center mt-2">
                                                                    {item.instactive == 1 && (
                                                                        <button
                                                                            onClick={() => {
                                                                                var tempuserobj = { ...userloggedinfobjcontext };
                                                                                tempuserobj.userinfo.currentinstactive = item.instid;
                                                                                setuserloggedinfobjcontext({ ...tempuserobj });
                                                                                routedispatchercontext('/dashboard');
                                                                            }}
                                                                            class={`${generalstyles.btn} ${generalstyles.btn_primary}` + ' mr-1 ml-1 '}
                                                                        >
                                                                            {lang.dashboardhome}
                                                                        </button>
                                                                    )}
                                                                    <button class={`${generalstyles.btn} ${generalstyles.btn_success}` + ' mr-1 ml-1 '}>{lang.upgrade}</button>
                                                                </div>
                                                                <p class={langdetect == 'en' ? 'text-light ml-auto mb-0 mt-1 font_14 ' : 'text-light mr-auto mb-0 mt-1 font_14 '}>
                                                                    {lang.createdon} {item.timestamp}
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Modal show={showCreateStoreModal} onHide={() => setshowCreateStoreModal(false)} centered size="md">
                            <Modal.Header style={{ paddingBottom: 0 }}>
                                <div className="row w-100 m-0">
                                    <div class={langdetect == 'en' ? 'col-lg-10 col-md-10 col-sm-10' : 'col-lg-10 col-md-10 col-sm-10 text-right'}>
                                        <p class="modal-title mb-0">{lang.createnewstore}</p>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2">
                                        <div class={langdetect == 'en' ? 'close-modal-container ml-auto' : 'close-modal-container mr-auto'} onClick={() => setshowCreateStoreModal(false)}>
                                            <IoMdClose size={18} />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <hr className="mt-1 mb-1" />
                                    </div>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <form class="row w-100 m-0">
                                    <div class="col-lg-12 mb-2 p-0">
                                        <div className="col-lg-12 mb-2 pl-2 pr-2">
                                            <div class={formstyles.welcome_modal_form_control}>
                                                <label className={langdetect == 'en' ? formstyles.input_label_name : formstyles.input_label_name + ' float-right '}>{lang.storename}</label>
                                                <input
                                                    type="text"
                                                    className={`${welcomestyles.welcome_modal_form_control} ${welcomestyles.form_control}`}
                                                    style={{ paddingTop: '18px', paddingBottom: '18px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-2 p-0">
                                        <div className="col-lg-12 mb-2 pl-2 pr-2">
                                            <label
                                                className={
                                                    langdetect == 'en' ? formstyles.input_label_name + ' col-lg-12 p-0 ' : formstyles.input_label_name + ' float-right text-right col-lg-12 p-0 '
                                                }
                                            >
                                                {lang.type}
                                            </label>
                                            <Select styles={langdetect == 'en' ? welcomeselectstyles : welcomeselectstylesTranslated} isSearchable={false} placeholder={lang.chooseoption} />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-2 p-0">
                                        <div className="col-lg-12 mb-2 pl-2 pr-2">
                                            <label
                                                className={
                                                    langdetect == 'en' ? formstyles.input_label_name + ' col-lg-12 p-0 ' : formstyles.input_label_name + ' float-right text-right col-lg-12 p-0 '
                                                }
                                            >
                                                {lang.currency}
                                            </label>
                                            <Select styles={langdetect == 'en' ? welcomeselectstyles : welcomeselectstylesTranslated} isSearchable={false} placeholder={lang.chooseoption} />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-2 p-0">
                                        <div className="col-lg-12 mb-2 pl-2 pr-2">
                                            <label
                                                className={
                                                    langdetect == 'en' ? formstyles.input_label_name + ' col-lg-12 p-0 ' : formstyles.input_label_name + ' float-right text-right col-lg-12 p-0 '
                                                }
                                            >
                                                {lang.language}
                                            </label>
                                            <Select
                                                // options={languageOptions}
                                                styles={langdetect == 'en' ? welcomeselectstyles : welcomeselectstylesTranslated}
                                                isSearchable={false}
                                                placeholder={lang.chooseoption}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-lg-12 text-center mb-2">
                                    <button class={`${generalstyles.btn} ${generalstyles.btn_primary} ${formstyles.welcome_modal_btn_primary}`} onClick={() => setbuttonClicked(true)}>
                                        <span class={buttonClicked ? generalstyles.fadeOutButton : generalstyles.fadeInButton}>{lang.createstore}</span>
                                        <div class={buttonClicked ? `${generalstyles.fadeInButton} ${generalstyles.btn_preloader}` : generalstyles.fadeOutButton}>
                                            <CircularProgress color="#fff" width="20px" height="20px" duration="1s" />
                                        </div>
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
