import axios from 'axios';
import { serverbaselink } from '../../Env_Variables';
const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const fetchinstinfo = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchinstinfo',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchinstitutes = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchinstitutes',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const instcrud = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/instcrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
