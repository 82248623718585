import React, { Component, useState } from 'react';
export const Loggedincontext = React.createContext();

export const Loggedincontext_provider = (props) => {
    const [isloggedincontext, setisloggedincontext] = useState(false);
    const [instbranchescontext, setinstbranchescontext] = useState([]);
    const [useraccesspagescontext, setuseraccesspagescontext] = useState([]);
    const [userloggedinfobjcontext, setuserloggedinfobjcontext] = useState({
        lastquantitychecker: '',
        nextquantitychecker: '',
        userinfo: {
            name: '',
            email: '',
            mobile: '',
            type: '',
            useractive: '',
            currentinstactive: '',
        },
        currentinstinfo: {
            instname: '',
            instlogo: '',
            instsubdomain: '',
        },
    });

    return (
        <Loggedincontext.Provider
            value={{
                isloggedincontext,
                setisloggedincontext,
                userloggedinfobjcontext,
                setuserloggedinfobjcontext,
                instbranchescontext,
                setinstbranchescontext,
                useraccesspagescontext,
                setuseraccesspagescontext,
            }}
        >
            {props.children}
        </Loggedincontext.Provider>
    );
};

export const Loggedincontext_consumer = Loggedincontext.Consumer;
