import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { LanguageContext } from '../../../LanguageContext';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';

import { Link } from 'react-router-dom';
import { Loggedincontext } from '../../../Loggedincontext.js';
import { Routedispatcher } from '../../../Routedispatcher.js';
import formstyles from '../Generalfiles/CSS_GENERAL/form.module.css';
import generalstyles from '../Generalfiles/CSS_GENERAL/general.module.css';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { logincheck } from '../../API/Login_API';
import { serverbaselink } from '../../../Env_Variables';
// import Header from
const Login = () => {
    useEffect(() => {
        document.title = 'Tejarrtech';
    }, []);
    const { lang, langdetect } = React.useContext(LanguageContext);
    const [logininputs, setlogininputs] = useState({
        email: '',
        password: '',
        loginstatus: true,
    });
    const [buttonClicked, setbuttonClicked] = useState(false);
    const { setisloggedincontext } = React.useContext(Loggedincontext);
    const { routedispatchercontext } = React.useContext(Routedispatcher);

    const logincheckMutation = useMutation('logincheck', {
        mutationFn: logincheck,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            // NotificationManager.error('Error', 'Error In Searching users.');
        },
        onSuccess: (data, variables, context) => {
            var logininputstemp = { ...logininputs };
            logininputstemp.loginstatus = data.data.loggedin;
            setlogininputs({ ...logininputstemp });
            if (data.data.loggedin) {
                document.getElementById('loginform').submit();
            }
        },
    });
    return (
        <div class="row m-0 w-100 d-flex align-items-center justify-content-center pl-sm-3 pr-sm-3 mt-5 " style={{ height: '70vh', paddingLeft: '50px', paddingRight: '50px' }}>
            <div
                class={langdetect == 'en' ? 'col-xl-6 col-lg-6 col-md-12 p-0 mt-5 mt-md-0' : 'col-xl-6 col-lg-6 col-md-12 p-0 mt-5 mt-md-0 text-right'}
                data-aos="fade-right"
                data-aos-easing="ease-in-out"
            >
                <div class="row m-0 w-100">
                    <div class="col-lg-12 text-center mb-3 p-0">
                        <p class="m-0" style={{ fontSize: '40px', fontWeight: 600 }}>
                            Welcome Back
                            <br />
                            To Tejarrtech
                        </p>
                    </div>
                </div>
                <form method="POST" action={serverbaselink + '/surfer/login'} class="d-flex flex-column align-items-center mt-5 mt-md-0" id={'loginform'}>
                    <div class="row m-0 w-100">
                        {/* E-mail */}
                        <div class="col-lg-8 col-md-8 col-sm-12 mb-4 p-0 ml-auto mr-auto">
                            <p class={langdetect == 'en' ? formstyles.input_label + ' mb-2 ml-2 ' : formstyles.input_label + ' mb-2 mr-2 '} style={{ fontSize: '18px' }}>
                                {lang.email}:
                            </p>
                            <div class={formstyles.input_group}>
                                <i class="fa fa-at"></i>
                                <input
                                    type="text"
                                    className={`${formstyles.form_control}`}
                                    name="email"
                                    value={logininputs.email}
                                    onChange={(event) => {
                                        var logininputstemp = { ...logininputs };
                                        logininputstemp.email = event.target.value;
                                        setlogininputs({ ...logininputstemp });
                                    }}
                                />
                            </div>
                        </div>

                        {/* Password */}
                        <div class="col-lg-8 col-md-8 col-sm-12 mb-4 p-0 ml-auto mr-auto">
                            <p class={langdetect == 'en' ? formstyles.input_label + ' mb-2 ml-2 ' : formstyles.input_label + ' mb-2 mr-2 '} style={{ fontSize: '18px' }}>
                                {lang.password}:
                            </p>
                            <div class={formstyles.input_group}>
                                <i class="bx bxs-lock-alt"></i>
                                <input
                                    type="password"
                                    name="password"
                                    className={`${formstyles.form_control}`}
                                    value={logininputs.password}
                                    onChange={(event) => {
                                        var logininputstemp = { ...logininputs };
                                        logininputstemp.password = event.target.value;
                                        setlogininputs({ ...logininputstemp });
                                    }}
                                />
                            </div>
                            <Link to="/forgetpassword">
                                <p
                                    className={
                                        langdetect == 'en'
                                            ? `${generalstyles.text_light} ${generalstyles.font_15} ${generalstyles.text_primaryhover}` + ' mt-1 mr-2 mb-0 align-items-center text-right '
                                            : `${generalstyles.text_light} ${generalstyles.font_15} ${generalstyles.text_primaryhover}` + ' text-left mt-1 mr-2 mb-0 align-items-center '
                                    }
                                >
                                    Forgot Password?
                                </p>
                            </Link>
                        </div>

                        {/* Wrong Email or Password */}
                        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                            {!logininputs.loginstatus && <p class={`${generalstyles.text_danger} ${generalstyles.font_15}` + ' mt-4 '}>Wrong Email Or Password</p>}
                            <button
                                class={`${formstyles.btn_form} ${generalstyles.btn} ${generalstyles.btn_primary}` + '   shadow-bottom d-flex align-items-center justify-content-center m-auto '}
                                type={'button'}
                                disabled={logincheckMutation.isLoading ? true : false}
                                onClick={() => {
                                    if (logininputs.email.length != 0 && logininputs.password.length != 0) {
                                        logincheckMutation.mutate({
                                            email: logininputs.email,
                                            password: logininputs.password,
                                        });
                                    }
                                }}
                            >
                                <span class={logincheckMutation.isLoading ? generalstyles.fadeOutButton : generalstyles.fadeInButton}>Login</span>
                                <div class={logincheckMutation.isLoading ? generalstyles.fadeInButton + ' d-flex align-items-center justify-content-center ' : generalstyles.fadeOutButton}>
                                    <CircularProgress color="#fff" width="40px" height="4vh" duration="1s" />
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 d-lg-flex justify-content-center align-items-center d-md-none" data-aos="fade-left" data-aos-easing="ease-in-out">
                {/* <img src={login} style={{ width: '60%' }}></img> */}
                <h2>Tejarrtech</h2>
                <div class={generalstyles.square}></div>
                <div class={generalstyles.circle}></div>
                <div class={generalstyles.circle2}></div>
                <div class={generalstyles.square2}></div>
            </div>
        </div>
    );
};

export default Login;
