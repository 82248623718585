import React, { Component, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import { RiMessage3Line, RiUserStarLine } from 'react-icons/ri';
import { MdOutlineLocalShipping, MdOutlineCategory } from 'react-icons/md';
import { HiUsers, HiOutlineOfficeBuilding } from 'react-icons/hi';
import { CgWebsite } from 'react-icons/cg';
import { GoDeviceMobile } from 'react-icons/go';
import { BiHomeAlt } from 'react-icons/bi';
import { BsWindow, BsLayoutTextSidebarReverse, BsFillCollectionFill, BsFillFileSpreadsheetFill } from 'react-icons/bs';
import { FaRegChartBar, FaMoneyCheck, FaUserCog, FaUserLock, FaBoxes, FaShippingFast } from 'react-icons/fa';
import { AiOutlineShopping, AiOutlineTag, AiOutlineInbox, AiOutlineMobile, AiOutlineNotification } from 'react-icons/ai';
import { GiPieChart } from 'react-icons/gi';
import sidenavstyles from './components/Dashboard/Sidenavfiles/sidenav.module.css';

export const Contexthandlerscontext = React.createContext();
export const Contexthandlerscontext_provider = (props) => {
    let history = useHistory();
    const { lang, setlang, langdetect } = React.useContext(LanguageContext);
    const setroutedispatcherfunccontext = (route) => {
        history.push(route);
    };

    const [sidenavarray_context, setsidenavarray_context] = useState([
        {
            maintitle: lang.sidenav_dashboards,
            auth: ['instowner', 'inststuff'],
            subitems: [
                {
                    subtitlename: lang.sidenav_home,
                    auth: ['instowner', 'inststuff'],
                    isselected: false,
                    reactpath: '/dashboard/institute/home',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <BiHomeAlt />
                        </i>
                    ),
                },
            ],
        },
        {
            maintitle: lang.sidenav_orders,
            auth: ['instowner', 'inststuff'],
            subitems: [
                {
                    subtitlename: lang.sidenav_orders,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/orders',
                    icon: (
                        <span>
                            <i class={sidenavstyles.metismenu_icon}>
                                <AiOutlineShopping size={18} />
                            </i>
                        </span>
                    ),
                },
                {
                    subtitlename: 'Sub ' + lang.sidenav_orders,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/suborders',
                    icon: (
                        <span>
                            <i class={sidenavstyles.metismenu_icon}>
                                <AiOutlineShopping size={18} />
                            </i>
                        </span>
                    ),
                },
                {
                    subtitlename: lang.sidenav_promocodes,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/promocodes',
                    icon: <i class={sidenavstyles.metismenu_icon + ' bx bx-bookmarks '}></i>,
                },
                {
                    subtitlename: lang.sidenav_customers,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/customers',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <RiUserStarLine />
                        </i>
                    ),
                },
                {
                    subtitlename: 'Agel requests',
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/agelrequests',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <RiUserStarLine />
                        </i>
                    ),
                },
            ],
        },
        {
            maintitle: lang.sidenav_products,
            auth: ['instowner', 'inststuff'],
            subitems: [
                {
                    subtitlename: lang.sidenav_products,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/products',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <AiOutlineTag size={18} />
                        </i>
                    ),
                },
                {
                    subtitlename: 'Audit Products',
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/auditproducts',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <AiOutlineTag size={18} />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_collections,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/collections',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <AiOutlineInbox size={18} />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.subparentcollections,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/subparentcollections',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <BsFillFileSpreadsheetFill size={18} />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.parentcollections,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/parentcollections',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <BsFillCollectionFill size={18} />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.categories,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/categories',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <MdOutlineCategory size={18} />
                        </i>
                    ),
                },
            ],
        },
        {
            maintitle: lang.sidenav_management,
            auth: ['instowner', 'inststuff'],
            subitems: [
                {
                    subtitlename: lang.sidenav_branches,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/branches',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <HiOutlineOfficeBuilding size={18} />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_users,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/users',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <HiUsers />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_vendors,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/vendors',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <FaBoxes />
                        </i>
                    ),
                },
                {
                    subtitlename: 'Vendor Requests',
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/newvendorrequests',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <FaBoxes />
                        </i>
                    ),
                },
                {
                    subtitlename: 'Lenders',
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/lenders',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <FaBoxes />
                        </i>
                    ),
                },
                // {
                //     subtitlename: lang.sidenav_mobileapplication,
                //     auth: ['instowner', 'inststuff'],
                //     isselected: false,
                //     reactpath: '/dashboard/institute/mobileapplication',
                //     icon: (
                //         <i class={sidenavstyles.metismenu_icon}>
                //             <GoDeviceMobile />
                //         </i>
                //     ),
                // },
            ],
        },

        {
            maintitle: lang.sidenav_onlinestore,
            auth: ['instowner', 'inststuff'],
            subitems: [
                {
                    subtitlename: 'Website Editor',
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/editor/webapp/all/all',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <CgWebsite />
                        </i>
                    ),
                },
                {
                    subtitlename: 'Mobile App Editor',
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/editor/mobileapp/all/all',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <GoDeviceMobile />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_shipping,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/Shipping',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <MdOutlineLocalShipping />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_couriers,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/couriers',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <FaShippingFast />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_onlinepaymentmethods,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/payment',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <FaMoneyCheck />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_policies,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/policies',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <BsLayoutTextSidebarReverse />
                        </i>
                    ),
                },
            ],
        },
        {
            maintitle: lang.sidenav_settings,
            auth: ['instowner', 'inststuff'],
            subitems: [
                {
                    subtitlename: lang.sidenav_institutesettings,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/instsettings',
                    icon: <i class={sidenavstyles.metismenu_icon + ' bx bx-cog '}></i>,
                },
                {
                    subtitlename: lang.sidenav_generalsettings,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/generalsettings',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <FaUserCog class="h-100" />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_securitylayers,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/securitylayers',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <FaUserLock />
                        </i>
                    ),
                },

                {
                    subtitlename: lang.sidenav_automatednotifier,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/notifiergrouprouter',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <AiOutlineNotification />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_smsservice,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/smsservice',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <RiMessage3Line />
                        </i>
                    ),
                },
            ],
        },
        {
            maintitle: lang.sidenav_reports,
            auth: ['instowner', 'inststuff'],
            subitems: [
                {
                    subtitlename: lang.sidenav_analytics,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/analytics',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <FaRegChartBar />
                        </i>
                    ),
                },
                {
                    subtitlename: lang.sidenav_reports,
                    isselected: false,
                    auth: ['instowner', 'inststuff'],
                    reactpath: '/dashboard/institute/reports',
                    icon: (
                        <i class={sidenavstyles.metismenu_icon}>
                            <GiPieChart />
                        </i>
                    ),
                },
            ],
        },
        {
            maintitle: 'Tabex Admin Mangement',
            auth: ['tabexadmin'],
            subitems: [
                {
                    subtitlename: 'Institutes',
                    isselected: false,
                    auth: ['tabexadmin'],
                    reactpath: '/dashboard/institute/tabexadmin/institutes',
                    icon: <i class={sidenavstyles.metismenu_icon + ' bx bx-cog '}></i>,
                },
                {
                    subtitlename: 'Users Dashboard',
                    isselected: false,
                    auth: ['tabexadmin'],
                    reactpath: '/dashboard/institute/tabexadmin/users',
                    icon: <i class={sidenavstyles.metismenu_icon + ' bx bx-cog '}></i>,
                },
            ],
        },
    ]);
    const [pagetitle_context, setpagetitle_context] = useState('');
    const setsidenavactive_context = (route) => {
        var temparr = [...sidenavarray_context];
        temparr.forEach((sideelement, index) => {
            sideelement.subitems.forEach((subsideelement, index) => {
                if (subsideelement.reactpath == route) {
                    subsideelement['isselected'] = true;
                } else {
                    subsideelement['isselected'] = false;
                }
            });
        });
        setsidenavarray_context([...temparr]);
    };
    return (
        <Contexthandlerscontext.Provider
            value={{
                sidenavarray_context,
                setsidenavarray_context,
                pagetitle_context,
                setpagetitle_context,
                setsidenavactive_context,
            }}
        >
            {props.children}
        </Contexthandlerscontext.Provider>
    );
};
