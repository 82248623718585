import React, { Component } from 'react';
import Cookies from 'universal-cookie';
export const LanguageContext = React.createContext();
export class LanguageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: {
                en: {
                    Tejarrtechshops: 'Tejarrtech',
                    // Side Nav & Page Titles
                    sidenav_home: 'Home',
                    sidenav_dashboard: 'Dashboard',
                    sidenav_stores: 'Stores',
                    sidenav_websites: 'Websites',
                    sidenav_orders: 'Orders',
                    sidenav_products: 'Products',
                    sidenav_collections: 'Collections',
                    parentcollections: 'Parent Collections',
                    subparentcollections: 'Sub Parent Collections',
                    sidenav_customers: 'Customers',
                    sidenav_promocodes: 'Promo Codes',
                    sidenav_pricing: 'Pricing',
                    sidenav_design: 'Design',
                    sidenav_websitetemplates: 'Website Templates',
                    sidenav_mobileapptemplates: 'Mobile Templates',
                    sidenav_domain: 'Domain',
                    sidenav_analytics: 'Analytics',
                    sidenav_policies: 'Policies',
                    sidenav_generalsettings: 'General Settings',
                    sidenav_onlinepaymentmethods: 'Online Payment Methods',
                    sidenav_shipping: 'Shipping',
                    sidenav_institutesettings: 'Institute Settings',
                    sidenav_branches: 'Branches',
                    pagetitle_showorder: 'Show Order',
                    sidenav_paymentmethods: 'Payment Methods',
                    sidenav_addnewproduct: 'Add New Product',
                    sidenav_mobileapplication: 'Mobile App',
                    sidenav_users: 'Users',
                    sidenav_smsservice: 'SMS Service',
                    sidenav_securitylayers: 'Security Layers',
                    sidenav_automatednotifier: 'Automated Notifier',
                    sidenav_vendors: 'Vendors',
                    sidenav_couriers: 'Couriers',
                    sidenav_reports: 'Reports',

                    // Side Nav Titles
                    sidenav_dashboards: 'Dashboards',
                    sidenav_management: 'Management',
                    sidenav_onlinestore: 'Online Store',
                    sidenav_settings: 'Settings',

                    nosecutitylayersfound: 'No Security Layers Found',

                    // Generall
                    showinfilter: 'Show in Filer',
                    chosentags: 'Chosen Tags',
                    tableformat: 'Table Format',
                    cities: 'Cities',
                    state: 'State',
                    nostatusfound: 'No Status Found',
                    totalusers: 'Total Users',
                    from: 'From',
                    to: 'To',
                    filter: 'Filter',
                    showfilter: 'Show Filter',
                    searchvendors: 'Search Vendors',
                    searchcollections: 'Search Collections',
                    salestatus: 'Sale status',
                    hassale: 'Has Sale',
                    nosale: 'No Sale',
                    all: 'All',
                    details: 'Details',
                    products: 'Products',
                    chooseoption: 'Choose Option',
                    totalorders: 'Total Number Of Orders',
                    totalorders2: 'Total Orders',
                    totalsales: 'Total Sales',
                    lowstockproducts: 'Low Stock Prodcuts',
                    outofzone: 'Out Of Zone',
                    actiontype: 'Action Type',
                    clearfilters: 'Clear Filters',
                    online: 'Online',
                    apply: 'Apply',
                    remove: 'Remove',
                    notes: 'Notes',
                    domain: 'Domain',
                    source: 'Source',
                    byyou: 'by you',
                    online: 'Online',
                    customer: 'Customer',
                    guest: 'Guest',
                    default: 'Default',
                    choose: 'Choose',
                    done: 'Done',
                    image: 'Image',
                    deleteall: 'Delete All',
                    disclaimer: 'Disclaimer',
                    updatelogo: 'Update Logo',
                    expirydate: 'Expiry Date',
                    institutes: 'Institutes',
                    createdon: 'Created On',
                    mobile: 'Mobile',
                    country: 'Country',
                    address: 'Address',
                    send: 'Send',
                    select: 'Select',
                    password: 'Password',
                    note: 'Note',
                    selectall: 'Select All',
                    unselectall: 'Unselect All',
                    title: 'Title',
                    sale: 'Sale',
                    price: 'Price',
                    saleprice: 'Sale Price',
                    onsale: 'On Sale',
                    offsale: 'Off Sale',
                    languages: 'Languages',
                    emailto: 'Email to',
                    paymentdetails: 'Payment Details',
                    archive: 'Archive',
                    branch: 'Branch',
                    name: 'name',
                    email: 'E-mail',
                    phonenumber: 'Phone Number',
                    edit: 'Edit',
                    delete: 'Delete',
                    description: 'Description',
                    ifyespleaswrite: 'If Yes, Please Write',
                    pleasewrite: 'Please Write',
                    warning: 'Warning',
                    search: 'Search...',
                    total: 'Total',
                    date: 'Date',
                    manage: 'Manage',
                    egp: 'EGP',
                    show: 'Show',
                    notadded: 'Not Added',
                    back: 'Back',
                    save: 'Save',
                    submit: 'Submit',
                    addtranslation: 'Add translation',
                    removetranslation: 'Remove Translation',
                    contactinformation: 'Contact Information',
                    add: 'Add',
                    arabictranslaion: 'Arabic Translation',
                    englishtranslation: 'English Translation',
                    for: 'For',
                    option: 'Option',
                    options: 'Options',
                    done: 'Done',
                    hello: 'Hello',
                    arabic: 'Arabic',
                    english: 'English',
                    livepreview: 'Live Preview',
                    subscribe: 'Subscribe',
                    logout: 'Logout',
                    language: 'Language',
                    allproducts: 'All Products',
                    chosenproducts: 'Chosen Products',
                    pleasecompletethemissingfields: 'Please Complete The Missing Fields',
                    product_s_: 'Product(s)',

                    // Status
                    securitygroup: 'Security Group',
                    status: 'Status',
                    active: 'Active',
                    draft: 'Draft',
                    notactive: 'Inactive',
                    enable: 'Enable',
                    disable: 'Disbale',
                    required: 'Required',
                    optional: 'Optional',
                    visible: 'Visible',
                    hidden: 'Hidden',
                    pending: 'Pending',
                    waitingforpickup: 'Waiting For Pick Up',
                    pickedup: 'Picked Up',

                    inputfield: 'Input Field',
                    selectbox: 'Selectbox',

                    // Welcome Page
                    manageyourstores: 'Manage Your Stores,',
                    createnewstore: 'Create New Store',
                    youhavenotcreatedanystoresyet: 'You have not created any stores yet.',
                    yourstores: 'Your Stores:',
                    storename: 'Store Name',
                    freetrial: 'Free Trial',
                    dashboardhome: 'Dashboard',
                    upgrade: 'Upgrade',
                    expired: 'Expired',
                    type: 'Type',
                    currency: 'Currency',
                    createstore: 'Create Store',
                    noinstitutesyet: "You don't have any institutes yet",
                    createnewinst: 'Create New Institute',

                    // Home
                    golivenow: 'Go Live Now!',
                    golivedesc: "Don't miss another day without being online!",
                    stepstocomplete: 'Steps To Complete',
                    recentactivity: 'Recent Activity',
                    homecard_createyourstore: 'Create Your Store',
                    homecard_addproducts: 'Add Products',
                    homecard_addcollections: 'Add Collections',
                    homecard_customizewebsite: 'Customize your website',
                    homecard_addshippingdetails: 'Add shipping details',
                    homecard_golive: 'Go Live!',
                    subscribenow: 'Subscribe',

                    // Home2
                    sales: 'Sales',
                    totalsalestoday: 'Total Sales Today',
                    profit: 'Profit',
                    ratioperday: 'Ratio Per Day',
                    totalorderstoday: 'Total Orders Today',
                    visits: 'Visits',
                    totalvisitsfortoday: "Total Today's Visits",

                    recentorders: 'Recent Orders',
                    quickactions: 'Quick Actions',
                    addcollection: 'Add Collection',
                    editcollection: 'Edit Collection',

                    // Branches
                    addbranch: 'Add Branch',
                    editbranch: 'Edit Branch',
                    deletebranch: 'Delete Branch',
                    nobranchesyet: 'No branches yet',
                    pleaseaddbranchname: 'Please add branch name',

                    // Shipping
                    updateshippingandaddressdetails: 'Update Adderss & Shipping Details',
                    shipping: 'Shipping',
                    chosenzones: 'Chosen Zones',
                    addzone: 'Add Zone',
                    zonename: 'Zone Name',
                    zonerateprice: 'Zone Rate Price',
                    zoneratesaleprice: 'Zone Rate Sale Price',
                    countries: 'Countries',
                    editzone: 'Edit Zone',
                    deletezone: 'Delete Zone',
                    youhavenotaddedanyzonesyet: 'No Zones have been added yet',
                    zonestates: 'Zone States',
                    shippingprice: 'Shipping Rate',
                    shippingsaleprice: 'Shipping Sale Price',
                    selectedzones: 'Selected Zones',
                    governorate: 'Governorate',
                    city: 'City',

                    // Couriers
                    zoneid: 'Zone ID',
                    zonename: 'Zone Name',
                    zonecities: 'Zone Cities',
                    allcouriers: 'All Couriers',
                    addcourier: 'Add New Courier',
                    couriername: 'Courier Name',
                    zones: 'Zones',
                    addnewcourier: 'Add New Courier',
                    editcourier: 'Edit Courier Information',
                    deletecourier: 'Delete Courier',
                    nocouriersyet: 'No Couriers yet',

                    // Products
                    tagname: 'Tag Name',
                    addtag: 'Add Tag',
                    edittag: 'Edit Tag',
                    chooseproducttags: 'Choose Product Tags',
                    productoptionmanagement: 'Product Options Management',
                    measurementunit: 'Measurement Unit',
                    editproductinformation: 'Edit Product Information',
                    productinformation: 'Product Information',
                    productgallery: 'Product Gallery',
                    inventorysettings: 'Inventory Settings',
                    stockandpricing: 'Stock & Pricing',
                    producttags: 'Product Tags',
                    importproducts: 'Import Products',
                    productstags: 'Products Tags',
                    productslogs: 'Products Logs',
                    thresholdquantity: 'Threshold quantity',
                    assignproductoptions: 'Assign Product Options',
                    assign: 'Assign',
                    deassign: 'De-assign',
                    assignvalues: 'Assign Values',
                    // assignoptionstoproduct: 'Assign Option To This Product',
                    doyouwanttodeletethisproduct: 'Do You Want To Delete This Product?',
                    addproducts: 'Add Products',
                    productvariants: 'Product Variants',
                    showproductvariants: 'Show Variants',
                    noproductsyet: 'You have no products',
                    editproduct: 'Edit Product',
                    addproduct: 'Add Product',
                    deleteproduct: 'Delete Product',
                    addnewproduct: 'Add New Product',
                    filterbyavailability: 'Filter By Availability',
                    filterby: 'Filter By',
                    unlisted: 'Unlisted',
                    productname: 'Product Name',
                    collection: 'Collection',
                    inventorystatus: 'Inventory Status',
                    limitedquantity: 'Limited Quantity',
                    alwaysavailable: 'Always Available',
                    exportproductsasCSV: 'Export Products As CSV',
                    export: 'Export',
                    exportproducts: 'Export Products',
                    currentpage: 'Current Page',
                    selectedproducts: 'Selected Products',
                    filteredproducts: 'Filtered Products',
                    productavailability: 'Product Availability',
                    changeproductavailability: 'Change Product Availability',
                    doyouwantchangeprodavailto: 'Do You Want To Change Product Availability To',
                    changeprodavailtodisclaimer1: 'Changing product availavility to',
                    productinformation: 'Product Information',
                    inventory: 'Inventory',
                    inventorysettings: 'Inventory Settings',
                    storagekeepingunit: 'Storage Keeping Unit',
                    sku: 'SKU',
                    updateproductinformation: 'Update Product Information',
                    uploadproductimages: 'Upload Product Images',
                    productgallery: 'Product Gallery',
                    noimages: 'No Images',
                    defaultimagedisclaimer: 'Choose default image by clicking on the image.',
                    updateinventory: 'Update Inventory',
                    updateinventorysettings: 'Update Settings Inventory',
                    minimumquantity: 'Minimum Quantity',
                    defaultpricing: 'Default Pricing',
                    optional_ifthereisanysale_: 'Optional (If There Is Any Sale)',
                    updatepricing: 'Update Pricing',
                    totalquantity: 'Total Quantity',
                    orderedquantity: 'Ordered Quantity',
                    currentquantity: 'Current Quantity',
                    updatequantity: 'Update Quantity',
                    newquantity: 'New Quantity',
                    changetype: 'Change Type',
                    updatequantity: 'Update Quantity',
                    product: 'Product',
                    // Options
                    variantsku: 'Variant SKU',
                    addoptionsdisclaimer: 'You Need To Add Product Options First',
                    productoptions: 'Product Options',
                    addproductoptions: 'Add Product Options',
                    editproductoptions: 'Edit Product Options',
                    addoption: 'Add Option',
                    editoption: 'Edit Option',
                    optionname: 'Option Name',
                    optionvalue: 'Option Value',
                    optionvalues: 'Option Values',
                    productvariatname: 'Product Variant Name',
                    productvariatoptions: 'Product Variant Options',
                    pressentertosubmitvalues: 'Press Enter To Submit Value',
                    waittillvalueisadded: 'Please Wait. We Are Adding Your Value.',
                    pleaseaddoptionnameandvalues: 'Please Add Option Name and Values',
                    optionsaleprice: 'Option Sale Price',
                    optionprice: 'Option Price',
                    // Variants
                    enablevariants: 'Enable Variants',
                    disablevariants: 'Disable Variants',
                    novariantsyet: 'You have not added any variants yet',
                    choosevariantimage: 'Choose Variant Image',
                    variantimage: 'Variant Image',
                    variants: 'Variants',
                    variant2: 'Variants',
                    variantpricing: 'Variant Pricing',
                    variantprice: 'Variant Price',
                    variantsaleprice: 'Variant Sale Price',
                    updatevariant: 'Update Variant',
                    addvariant: 'Add Variant',
                    addproductvariants: 'Add Product Variants',
                    editproductvariants: 'Update Product Variants',
                    currentvariant: 'Current Variant',
                    noproductimages: 'No Product Images',
                    noproductimagesdisclaimer: 'Please Upload Images For The Product First',
                    // Collections
                    choosecollection: 'Choose Collection',
                    allcollections: 'All Collections',
                    collectiondescription: 'Collection Description',
                    addnewcollection: 'Add New Collection',
                    nocollectionsyet: "You Haven't added any collections yet",
                    collectioninformation: 'Collection Information',
                    collectionname: 'Collection Name',
                    collectionlogo: 'Collection Logo',
                    collectionproducts: 'Collection Products',
                    completecollectioninfo: 'Please Complete all Collection Information First',
                    uploadlogo: 'Uplaod Logo',
                    addcollection: 'Add Collection',
                    editcollection: 'Edit Collection',
                    deletecollection: 'Delete Collection',
                    numberofproducts: 'Number Of Products',

                    // Orders & Customers
                    addressandshipping: 'Address & Shipping',
                    doyouwanttodeletecustomer: 'Do You Want To Delete this Customer?',
                    logs: 'Detailed Logs',
                    updateorderpayment: 'Update Order Payment',
                    orderitems: 'Order Items',
                    ordersummary: 'Order Summary',
                    updateaddress: 'Update Address',
                    updatestatus: 'Update Status',
                    trackactivity: 'Track Activity',
                    viewordersnotauth: ' Not Authorized To View Orders',
                    noordersyet: ' you have no orders yet',
                    resultsfromsearch: 'Results From Search',
                    orderactivity: 'Order Activity',
                    searchbyvendors: 'Search By Vendors',
                    totalspent: 'Total Spent',
                    numberoforders: 'Number Of Orders',
                    orderstatus: 'Order Status',
                    chooseorderstatus: 'Choose Order Status',
                    customerhavenoordersyet: 'No orders found',
                    noorderbythiscourier: 'No orders found by this courier',
                    previousorders: 'Previous Orders',
                    paymentfulfillstatus: 'Payment & Fulfillment Status',
                    customerinformation: 'Customer Information',
                    shippingaddress: 'Shipping Address',
                    billingaddress: 'Billing Address',
                    allorders: 'All Orders',
                    choosecustomer: 'Choose Customer',
                    customer: 'Cutsomer',
                    guest: 'Guest',
                    chooseoptions: 'Choose Options',
                    havenotaddedproducts: 'You havenot added any products yet',
                    addquantity: 'Add Quantity',
                    quantity: 'Quantity',
                    addneworder: 'Add New Order',
                    addorder: 'Add Order',
                    filterbypaymentstatus: 'Filter By Payment Status',
                    filterbyfulfillmentstatus: 'Filter By Fulfilment Status',
                    searchordernum: 'Search By Order Number',
                    ordernumber: 'Order #',
                    customername: 'Customer Name',
                    items: 'Items',
                    orderdetails: 'Order Details',
                    choosepaymentstatus: 'Choose Payment Status',
                    choosefulfillmentstatus: 'Choose Fulfillment Status',
                    fulfillorder: 'Fulfill Order',
                    cancelfulfillment: 'Cancel Fulfillment',
                    cancelfulfillmentconfirm: 'Do you want to cancel this order fulfillemnt?',
                    editfulfillmentstatus: 'Edit Fulfillment Status',
                    dependonoption: 'Depends On Options',
                    orderno: 'Order No',
                    ordernumber: 'Order Number',
                    item: 'Item',
                    subtotal: 'Sub Total',
                    shippingrate: 'Shipping Rate',
                    discount: 'Discount',
                    paidamount: 'Paid Amount',
                    editorderstatus: 'Edit Order Status',
                    deleteorderstatus: 'Delete Order Status',
                    addorderstatus: 'Add Order Status',
                    titleinen: 'Title in English',
                    titleinar: 'Title in Arabic',
                    bgcolor: 'Background Color',
                    forstaff: 'For Staff',
                    forcustomers: 'For Customers',
                    customers: 'Customers',
                    staff: 'Staff',
                    additems: 'Add Items',
                    additem: 'Add Item',
                    removeitem: 'Remove Item',
                    edititem: 'Edit Item',
                    customernotes: 'Customer Notes',
                    ordertrackingactivity: 'Order Tracking Activity',
                    orderpaidstatusupdated: 'Order Paid Status Updated',
                    deleteorder: 'Delete Order',
                    removecustomer: 'Remove Customer',
                    removecustomerconfirmation: 'Do You Want To Remove This Customer?',
                    numberoforders: 'Orders',
                    totalspending: 'Total Spending',
                    youhavenotaddedanycustomersyet: 'You have not added any customers yet',
                    addnewcustomer: 'Add New Customer',
                    editcustomer: 'Edit Customer Information',
                    allcustomers: 'All Customers',
                    deletecustomer: 'Delete Customer',
                    deletewarningtext: 'Warning: all customer information and orders will be completely deleted',
                    showcustomerinfo: 'Show Customer Information',
                    youhavenptaddedproductsinorder: 'You havenot added any products in the order yet',
                    doyouwanttodelete: 'Do You Want To Delete',
                    fromordernum: 'From Order',
                    order: 'Order',

                    // Promocodes
                    promocode: 'Promo Code',
                    addpromocode: 'Add Promo Code',
                    youhavenocouponsyet: 'You Havenot added any promo codes yet',
                    couponname: 'Promo code Name',
                    usage: 'Usage',
                    doyouwanttodeletethiscoupon: 'Do you want to delete this Promo Code ?',
                    editpromocode: 'Edit Promo Code',
                    deletepromocode: 'Delete Promo Code',

                    // Policies
                    privacypolicy: 'Privacy Policy',
                    privacypolicycontent: 'Privacy Policy Content',
                    termsandconditions: 'Terms & Conditions',
                    refundpolicy: 'Refund Policy',
                    shippingpolicy: 'Shipping Policy',
                    policyinformation: 'Policy Information',
                    deletepolicytranswarningtext: 'Do you want to delete page translation?',
                    generatetemplate: 'Generate Template',

                    // Institute Settings
                    storeinformation: 'Store Information',
                    storename: 'Store Name',
                    storetype: 'Store Type',
                    storeindustry: 'Store Industry',
                    storelogo: 'Store Logo',
                    storefavouriteicon: 'Store Favourite Icon',
                    paymentsettings: 'Payment Settings',
                    updatecontactinformation: 'Update Contact Information',
                    updatestoreinformation: 'Update Store Information',
                    updatestorelogo: 'Update Store Logo',
                    updatestorefavicon: 'Update Store Favicon',
                    contactinfodesc: 'This is how your customers will contact you',
                    // Signup data
                    addnewdata: 'Add New Data',
                    editsignupdata: 'Edit Data',
                    deletesignupdata: 'Delete Data',
                    signupcustomerinfo: 'Signup Customer Information',

                    // Payment
                    payment: 'Payment',
                    deletepaymentmethod: 'Delete Payment Method',
                    cod: 'Cash On Delivery',
                    paymentmethod: 'Payment Method',
                    paymentmethods: 'Payment Methods',
                    onlinepaymentsettings: 'online payment method settings',
                    emailsettings: 'Email Settings',
                    choosepaymentmethod: 'Choose Payment Method',
                    integrationinstructions: 'Intergration Instructions',
                    paymenthassuccessfullydeleted: 'Payment Method is successfully deleted',
                    doyouwanttodeletepaymentmethod: 'Do You Sure You Want To Delete This Payment method?',
                    deletepayment: 'Delete Payment Method',
                    youhavenotaddedanypaymentmethodsyet: 'No Payment Methods Have Been Added Yet',
                    checked: 'Checked',
                    notchecked: 'Not Checked',
                    chosenpaymentmethods: 'Chosen Payment Methods',
                    emailsettingscontractinformation:
                        'Selecting “Yes” means that your customers will not receive emailed confirmations of completed, refunded, or voided transactions, even if the platform has enabled them globally. Customers may still receive confirmation emails from the provider. It’s useful to select “Yes” if you already send a transaction confirmation to your customers.',
                    usesandboxprovidercomment:
                        "If you checked Use provider sandbox, all payments will be sent to this provider's sandbox environment,and if not checked, payments will be sent to provider Live environment.",
                    choosecountry: 'Choose Country',
                    // Fawry //
                    fawrydescription: `Make sure you have your Fawry Account Manager to set the following url as your payment notification URL (Callback URL):
                          <br>https://moneyhash.io/providers/fawry/webhook/`,
                    providedbyfawrypayteamduringtheaccountsetup: 'Provided by fawry pay team during the account setup',
                    // Paymob //
                    paymobinstructions: `Make sure you add the following urls in all Payment Integrations in your Paymob Accept dashboard:
                            <br>https://moneyhash.io/providers/paymob/webhook/processed/ (in Transactionprocessed callback)
                            <br>https://moneyhash.io/providers/paymob/webhook/response/ (in Transaction response callback)`,
                    mobilewalletinputcomment: 'You must add +2 to phone number; ex: +20011111111',
                    // Cow pay //
                    cowpaycodeinputcomment: 'Provided by Cowpay team during the account setup.',
                    cowpaytokencomment: 'Authentication token for Staging or Production based on use_provider_sandbox field',
                    cowpaycheckboxcomment: "If checked, all payments will be sent to this provider's sandbox environment,and if not checked, payments will be sent to provider live environment.",
                    // Pay tabs //
                    paytabsinputcomment: 'Obtained From The Provider Dashboard',

                    // Analytics
                    connect: 'Connect',
                    connectto: 'Connect To',
                    disconnect: 'Disconnect',
                    id: 'ID',
                    id2: 'ID',
                    allowsyoutotrackwebsiteperformanceandcollectvisitorinsights: 'Allows you to track website performance and collect visitor insights.',
                    allowsyoutomeasureyouradvertisingROIandtrackyoursocialnetworkingsitesandapplications:
                        'Allows you to measure your advertising ROI and track your social networking sites and applications.',
                    allowsyoutomeasuretheeffectivenessofyouradvertising: 'Allows you to measure the effectiveness of your advertising.',

                    // General Settings
                    userinformation: 'User Information',
                    changeemail: 'Change Email',
                    subscriptiondetails: 'Subscription Details',
                    currentemail: 'Current Email',
                    newemail: 'New Email',
                    oldpassword: 'Old Password',
                    newpassword: 'New Password',
                    errorinchangingemail: 'Error In Changing Email',
                    errorinchangingpassword: 'Error In Changing Password',
                    serveremailchangeerror: 'Error, error changing your email, please contact Tejarrtechshops team.',
                    serverpasswordchangeerror: 'Error, error changing your password, please contact Tejarrtechshops team.',
                    updateuserinformation: 'Update User Information',
                    errorinaddinginformation: 'Error In Adding Information',
                    errorineditinginformation: 'Error In Editing Information',
                    dataupadated: 'Data Upadated Successfully',
                    servererrorinaddinginformation: 'Error, error adding information, please contact Tejarrtechshops team.',
                    servererrorineditinginformation: 'Error, error editing information, please contact Tejarrtechshops team.',
                    sendverificationcode: 'Send Verification Code',
                    verificationcoderequested: 'Verification Code Requested',
                    codeverified: 'Code Verified',
                    youremailhaschangesuccessfully: 'Your Email Has Changed Successfully',
                    pleaseinserttheverificationcode: 'Please Insert The Verification Code',
                    verificationcode: 'Verification Code',
                    packagename: 'Package Name',
                    duration: 'Duration',
                    fees: 'Fees',
                    annualfees: 'Annual Fees',
                    maximuminstitutes: 'Maximum Institutes',
                    mobileapplication: 'Mobile Application',
                    emailalreadyexists: 'Email Already Exists',
                    changepassword: 'Change Password',
                    passwordalreadyexists: 'Password Already Exists',
                    passwordchangedsuccessfully: 'Password Changed Successfully',
                    oldpasswordisincorrect: 'Old Password Is Incorrect',

                    // Uploder
                    chosenfiles: 'Chosen files',
                    uploadfiles: 'Upload Files',
                    uploaderdropdowntext: 'Drag & Drop Files or Click To Browse',

                    // Mobile Application
                    notificationslogs: 'Notifications Logs',
                    mobileapplicationusers: 'Mobile Application Users',
                    loggedinusers: 'Logged-in Users',
                    alldevices: 'All Devices',
                    notificationid: 'Notification ID',
                    banned: 'Banned',
                    loggedin: 'Logged-In',
                    lastseen: 'last seen',
                    loggedindevicetype: 'Logged-in Device Type',
                    mob_deviceid: 'Mobile Device ID',
                    notificationallowed: 'Notifications Allowed',
                    sendnotification: 'Send Notification',
                    banemail: 'Ban E-mail',
                    notificationcontent: 'Notification Content',
                    notificationtitle: 'Notification Title',
                    sendnotificationto: 'Send Notification To',
                    notloggedin: 'Not Logged-in',
                    notallowed: 'Not Allowed',
                    allowed: 'Allowed',
                    website: 'Website',

                    // Users
                    adduser: 'Add New User',
                    edituser: 'Edit User Information',
                    deleteuser: 'Delete User',
                    youhavenousersyet: 'You Have No Users Yet',

                    // SMS SERVICE
                    smsserviceinformation: 'SMS Service Information',
                    senderid: 'Sender ID',
                    addsenderid: 'Add Sender ID',
                    thissenderid: 'this sender ID',
                    deletesenderid: 'Delete Sender ID',
                    sendername: 'Sender Name',
                    senderstatus: 'Sender Status',
                    taxpaper: 'Tax Paper',
                    instpaper: 'Inst Paper',
                    balance: 'Balance',
                    requestbalance: 'Request Balance',
                    numberofmsgs: 'Number Of Messages',
                    smslogs: 'SMS Logs',
                    logid: 'Log ID',
                    smsstatus: 'SMS Status',
                    reason: 'Reason',
                    smscontent: 'SMS Content',
                    requestedbalance: 'Requested Balance',
                    noofmsgs: 'Number Of Messages',
                    balancelogs: 'Balance Logs',
                    balancelogid: 'Balance Log ID',
                    nosmsrequested: 'Number Of SMS Requested',
                    addsernderid: 'Add Sender ID',
                    addbalance: 'Add Balance',
                    sendmessage: 'Send Message',
                    smsbody: 'SMS Body',
                    receiverphonenumber: 'Receiver Phone Number',

                    // Vendors
                    allvendors: 'All Vendors',
                    vendorname: 'Vendor Name',
                    location: 'Location',
                    addvendor: 'Add Vendor',
                    deletevendor: 'Delete Vendor',
                    editvendorinfo: 'Edit Vendor Information',
                    doyouwanttodeletethisvendor: 'Do You Want To Delete This Vendors?',

                    // Categories
                    nocollections: 'No Categories Found',
                    categories: 'Categories',
                    addcategory: 'Add Category',
                    deletecategory: 'Delete Category',
                    editcategory: 'Edit Category',
                    categoryname: 'Category Name',
                    collections: 'Collections',
                    visibilitystatus: 'Visibility Status',
                    visibilityinfilter: 'Visibility Status in Fiter',
                    categorylogo: 'Category Logo',
                    categoryinfo: 'Category Information',
                    categorydescription: 'Category Description',
                    categorystatus: 'Category Status',
                    visibleinfilter: 'Visible in Filter',
                    visibletocustomers: 'Visible to Customers',
                    categorycollections: 'Category Collections',
                    chosencollections: 'Chosen Collections',

                    // Notifications
                    servererrorupdatevendors: 'Error in Updating Vendors. Please Contact Tejarrtech Team',
                    vendorsupdatesuccess: 'Vendors updated successfully',
                    servererrorupdatesecuritygp: 'Error In Updating Security Groups. Please Context Tejarrtech Team',
                    securitygpupdatedsuccess: 'Security Groups Updated Successfully',
                    // Products Notifications
                    optionsupdatessuccess: 'Options Updated Successfully',
                    servererrorupdateoptions: 'Error in Updating Options. Please contact Tejarrtech Team',
                    valuealreadyexists: 'Value already exists',
                    pleasewritevaluename: 'Please Write Value Name',
                    pleasewritevalues: 'Please Write Values',
                    pleasewriteoptionname: 'Please Write Option Name',
                    productaddedsuccess: 'Product is added successfully',
                    servererroraddingproduct: 'Error in Adding Product. Please contact Tejarrtech Team',
                    optionsupdatedsuccess: 'Product Options updated successfully',
                    servererroroptionsupdated: 'Error. Please Contact Tejarrtech Team ',
                    deleteproductsuccess: 'Product is Deleted',
                    servererrordeleteproduct: 'Error in Deleting Product. Please contact Tejarrtech Team',
                    errorupdateproduct: 'Error In Updating Product Information',
                    // Images
                    imageuploadedsuccess: 'Image(s) is/are uploaded successfully',
                    imagedeletedsuccess: 'Image is deleted Successfully',
                    servererroruploadingimage: 'Error in Uploading Images(s). Please Contact Tejarrtech Team',
                    servererrordeleteimage: 'Error in Deleting Image. Please Contact Tejarrtech Team',
                    // Product Images
                    galleryimagedeletedsuccess: 'Image is deleted Successfully',
                    servererrordeletegalleryimage: 'Error in Deleting Image. Please Contact Tejarrtech Team',
                    defaultimageupdatedsuccess: 'Default Image is Updated',
                    servererrorupdatedefaultimage: 'Error in Updating Default Image. Please Contact Tejarrtech Team',
                    // Quantity
                    erroraddingquantity: 'Error in Adding Quantity. Please Contact Tejarrtech Team',
                    // Prod Info
                    prodinfoupdatessuccess: 'Product Information is Updated Successfully',
                    servererrorupdateprodinfo: 'Error in Updating Product Information. Please Contact Tejarrtech Team',
                    // Choose Collection
                    pleasechoosecollection: 'Please Choose Collection',
                    servererrorchangeprodoptionvalues: 'Error in Changing Product Options Values. Please Contact Tejarrtech Team',
                    // Prod Avail
                    productavailupdatesuccess: 'Product Availability is Updated Successfully',
                    servererrorupdateprodavail: 'Error in Changing Product Availability. Please Contact Tejarrtech Team',
                    // Inventory
                    inventoryupdateduccess: 'Inventory is Updated Successfully',
                    servererrorupdateinventory: 'Error in Updating Inventory. Please Contact Tejarrtech Team',
                    // Variants
                    statuschangessuccess: 'Status is Changed Successfully',
                    servererrorchangevariantsstatus: 'Error in Changing Status Information. Please Contact Tejarrtech Team',
                    variantimageupdated: 'Variant Image is updated successfully',
                    variantupdatedsuccess: 'Variant is Updated successfully',
                    servererrorupdatevariant: 'Error in Updated Variant. Please Contact Tejarrtech Team',
                    variantaddedsuccess: 'Variant is added',
                    servererroraddvariant: 'Error in Adding Variant. Please Contact Tejarrtech Team',
                    variantdeletedsuccess: 'Variant is deleted',
                    servererrordeletevariant: 'Error in Deleting Variant. Please Contact Tejarrtech Team',
                    // Pricing
                    defaultpricingupdatedsuccess: 'Default Pricing is Updated Successfully',
                    servererrorupdatedefaultpricing: 'Error in Updating Default Pricing. Please Contact Tejarrtech Team',
                    // servererror: 'Error In Editing Translation, Please Contact Tejarrtechshops Team',
                    policiessavedsuccessfully: 'Data Saved Successfully',
                    servererrorsavepolicy: 'Error in Saving Data. Please Contact Tejarrtech Team',
                    translationsuccessfullysaved: 'Translation Successfully Saved',

                    // Orders Notifications
                    shippingzonefound: 'Shipping Zone Found',
                    promocoderemoved: 'Promo Code is Removed',
                    servererrorcheckingzones: 'Error In Checking Zones. Please Contact Tejarrtech Team',
                    promocodeapplied: 'Promo Code Applied',
                    servererrorapplyingpromocode: 'Error In Applying Promo Codes. Please Contact Tejarrtech Team',
                    pleasewriteyourpromocode: 'Please Write Your Promo Code',
                    orderisaddedsuccess: 'Order is added successfully',
                    servererroraddingorder: 'Error in Adding Order. Please Contact Tejarrtech Team',
                    pleaseaddproducts: 'Please Add Products First',
                    pleasechoosefulfillmentstatus: 'Please Choose Fulfillment Status',
                    pleasechoosepaidstatus: 'Please Choose Paid Status',
                    pleasechooseorderstatus: 'Please Choose Order Status',
                    itemisaddedsuccess: 'Item is added successfully',
                    itemisdeletedsuccess: 'Item is deleted successfully',
                    orderisdeletedsuccess: 'Order is deleted successfully',
                    statusiseditedsuccess: 'Order Status is edited successfully',
                    servererrordeleteorder: 'Error in deleting order. Please Contact Tejarrtech Team',
                    servererrorremoveitem: 'Error in removing item. Please Contact Tejarrtech Team',
                    quantityisadded: 'Quantity is added',
                    completestepstoplaceorder: 'Complete steps to place the order',
                    servererroreditstatus: 'Error in editing order status. Please Contact Tejarrtech Team',
                    pleaseaddquantity: 'Please add quantity',
                    servererrorsearchorders: 'Error In Searching For Order. Please Contact Tejarrtech Team',
                    servererrorupdatingorders: 'Error In Updating Order. Please Contact Tejarrtech Team',
                    orderupdatedsuccess: 'Order is Updated Successfully',
                    servererrorsearchvendors: 'Error In Searching For Vendors. Please Contact Tejarrtech Team',
                    choosecountrystatecity: 'Please Choose Country, State & City',
                    doyouwanttodeleteorder: 'Do You Want To Delete This Order?',
                    servererroreditingorderstatus: 'Error In Editing Order Status. Please Contact Tejarrtech Team',
                    orderstatusupdatedsuccess: 'Order Status is Updated Successfully',
                    // Collections Notifications
                    collectionaddedsuccessfully: 'Collection is added successfully',
                    collectiondeletedsuccessfully: 'Collection is deleted successfully',
                    collectioneditedsuccessfully: 'Collection is edited successfully',
                    collectionlogoupdatesucess: 'Collection logo is updated successfully',
                    servererroraddingcollection: 'Error In Adding Collection. Please Contact Tejarrtech Team',
                    servererroreditingcollection: 'Error in Editing Collection. Please Contact Tejarrtech Team',
                    servererrorupdatecollectionlogo: 'Error in Updating Collection Logo. Please Contact Tejarrtech Team',
                    itemisdeletedfromcollection: 'Product is removed from collection',
                    itemisaddedtocollection: 'Product is added to collection',
                    // Customers Notifications
                    addcustomerservererror: 'Error in Adding Customer. Please Contact Tejarrtech Team.',
                    editcustomerservererror: 'Error in Editing Customer. Please Contact Tejarrtech Team.',
                    deletecustomerservererror: 'Error in Deleting Customer. Please Contact Tejarrtech Team.',
                    customerhasbeensuccessfullyadded: 'Customer is added successfully ',
                    customerhasbeensuccessfullyedited: 'Customer information is edited Successfully ',
                    customerhasbeensuccessfullydeleted: 'Customer is deleted Successfully',
                    pleaseaddname: 'Please Add Name',
                    pleaseselectbranch: 'Please Select Branch',
                    // Shipping Notifications
                    servererroraddingzone: 'Error in Adding Zone. Please Contact Tejarrtech Team',
                    servererroreditingzone: 'Error in Editing Zone. Please Contact Tejarrtech Team',
                    servererrordeletingzone: 'Error in Deleting Zone. Please Contact Tejarrtech Team',
                    zoneeditedsuccess: 'Zone is edited successfully',
                    zoneaddedsuccess: 'Zone is add successfully',
                    zonedeletedsuccess: 'Zone is deleted successfully',
                    allstatesselected: 'All Stated are selected',
                    allstatesunselected: 'All Stated are removed',
                    // Branches Notifications
                    brancheditedsuccess: 'Branch is edited successfully',
                    branchaddessuccess: 'Branch is added successfully',
                    branchdeleted: 'Branch is deleted successfully',
                    errorinaddingbranchserver: 'Error in Adding Branch. Please Contact Tejarrtech Team',
                    erroreditingbranchserver: 'Error in Editing Branch. Please Contact Tejarrtech Team',
                    errordeletingbranchserver: 'Error in Deleting Branch. Please Contact Tejarrtech Team',
                    // Institute Settings Notifications
                    pleasecomleteyourcontactinformation: 'Please complete your contact information',
                    contactinfoupdatedsuccess: 'Contact Information is updated successfully',
                    servererrorcontactinfo: 'Error in updating contact information. Please contact Tejarrtech Team',
                    storeinfoupdatedsuccess: 'Store Information is updated successfully',
                    servererrorstoreinfo: 'Error in updating store information. Please contact Tejarrtech Team',
                    logoaddedsuccess: 'Logo is updated successfully.',
                    erroruploadingfavicon: 'Error in uploading favicon. Please contact Tejarrtech Team',
                    faviconupdatedsuccess: 'Favicon in updated successfully',
                    erroruploadinglogo: 'Error in uploading store logo. Please contact Tejarrtech Team',
                    pleaseuploadimage: 'Please upload an image first',
                    pleasecompletecompletestoreinfo: 'Please complete your contact information',
                    // Signup data
                    infoaddedsuccess: 'Added successfully',
                    infoeditedsuccess: 'Edited successfully',
                    infoeletedsuccess: 'Deleted successfully',
                    // Payment Notifications
                    paymentmethodadded: 'Payment Method is added successfully',
                    paymentmethodenabled: 'Payment Method is now enabled',
                    paymentmethoddisabled: 'Payment Method is now Disabled',
                    servererroraddpaymentmethod: 'Error in adding payment method. Please contact Tejarrtech Team',
                    servererrordeletepaymentmethod: 'Error in editing payment method. Please contact Tejarrtech Team',
                    servererrorchangeactivationpaymentmethod: 'Error in chnging activation status. Please contact Tejarrtech Team',
                    // Coupons Notifications
                    serveraddpromocodeerror: 'Error in Adding coupon, Please Contact Tejarrtech Team.',
                    servereditpromocodeerror: 'Error in Editing coupon, Please Contact Tejarrtech Team.',
                    serverdeletepromocodeerror: 'Error in Deleting coupon, Please Contact Tejarrtech Team.',
                    couponaddedsuccess: 'Coupon is added successfully',
                    couponeditedsuccess: 'Coupon is edited successfully',
                    coupondeletedsucess: 'Coupon is deleted',
                    // Users Notifications
                    servererroraddinguser: 'Error in Adding User. Please Contact Tejarrtech Team',
                    servererroreditinguser: 'Error in Editing User. Please Contact Tejarrtech Team',
                    servererrordeletinguser: 'Error in Deleting User. Please Contact Tejarrtech Team',
                    usereditedsuccess: 'User is edited successfully',
                    useraddedsuccess: 'User is add successfully',
                    userdeletedsuccess: 'User is deleted successfully',
                    // SMS Service Notifications
                    senderidaddedsuccess: 'Sender ID is added successfully',
                    senderiddeletedsuccess: 'Sender ID is deleted successfully',
                    servererroraddsenderid: 'Error in Adding Sender ID. Please Contact Tejarrtech Team',
                    servererrordeletesenderid: 'Error in Deleting Sender ID. Please Contact Tejarrtech Team',
                    servererrorsendsms: 'Error in Sending Message. Please Contact Tejarrtech Team',
                    sendsmssentsuccess: 'SMS Has Been Sent Successfully',
                    // Mobile Application Notifications
                    notificationsentsucceess: 'Notification is sent',
                    servererrorsendnotification: 'Error in Sending Notification. Please Contact Tejarrtech Team',
                    allusersselected: 'All Customers are Selected',
                    // Couriers Notifications
                    servererrorupdatecouriers: 'Error in Updating Couriers. Please Contact Tejarrtech Team',
                    couriersupdatedsuccessfully: 'Couriers are updated',
                    zonealreadyexists: 'Zone is already exists',
                    deletecourierconfirmation: 'Do You Want To Delete This Courier?',
                    // Categories Notifications
                    categoriesupdatedsuccess: ' Categories are updated successfuly',
                    servererrorerrorupdatingcategories: 'Error in Updating Categories. Please Contact Tejarrtech Team',
                    categoryaddedsuccess: 'Category is added successfuly',
                    servererrorerroraddingcategory: 'Error in adding categories. Please contact Tejarrtech Team',
                    errordeletecategory: 'Error in deleting categories. Please contact Tejarrtech Team',

                    // Paid and fulfilment Status
                    itemfulfillmentstatus: 'Item Fulfilment Status',
                    fulfillmentstatus: 'Fulfilment',
                    paidstatus: 'Paid',
                    paid: 'Paid',
                    unpaid: 'Unpaid',
                    canceled: 'Cancelled',
                    fulfilled: 'Fulfilled',
                    unfulfilled: 'Unfulfilled',
                    partiallyfulfilled: 'Partially Fulfilled',
                    enabled: 'Enabled',
                    disabled: 'Disabled',
                    noquantity: 'No quantity',
                    hasquantity: 'Has quantity',
                },

                ar: {
                    noquantity: 'لا يوجد كمية',
                    hasquantity: 'يوجد كمية',
                    Tejarrtechshops: 'متاجر Tejarrtech',
                    // Side Nav & Page Titles
                    sidenav_home: 'الصفحة الرئيسية',
                    sidenav_dashboard: 'الصفحة الرئيسية',
                    sidenav_stores: 'المتاجر',
                    sidenav_websites: 'المواقع',
                    sidenav_orders: 'الطلبات',
                    sidenav_products: 'المنتجات',
                    sidenav_collections: 'المجموعات',
                    parentcollections: ' المجموعات الرئيسية',
                    subparentcollections: ' المجموعات الثانوية',

                    sidenav_customers: 'العملاء',
                    sidenav_promocodes: 'اكواد الخصم',
                    sidenav_pricing: 'الإشتراك',
                    sidenav_design: 'التصميم',
                    sidenav_websitetemplates: 'تصميم الموقع',
                    sidenav_mobileapptemplates: 'تصميم التطبيق',
                    sidenav_domain: 'المجال',
                    sidenav_analytics: 'التحليلات',
                    sidenav_policies: 'الشروط و الاحكام',
                    sidenav_generalsettings: 'الإعدادات العامه',
                    sidenav_onlinepaymentmethods: 'طرق الدفع الألكترونيه',
                    sidenav_shipping: 'الشحن',
                    sidenav_institutesettings: 'إعدادات المنشأه',
                    sidenav_branches: 'الفروع',
                    pagetitle_showorder: 'عرض الطلب',
                    sidenav_paymentmethods: 'طرق الدفع',
                    sidenav_addnewproduct: 'إضافة منتج جديد',
                    sidenav_mobileapplication: 'تطبيق الهاتف',
                    sidenav_users: 'المستخدمين',
                    sidenav_smsservice: 'خاصيه الرسائل',
                    sidenav_securitylayers: 'طبقات الحمايه',
                    sidenav_automatednotifier: 'المخطر الآلي',
                    sidenav_vendors: 'الموردين',
                    sidenav_couriers: 'المندوبين',
                    sidenav_reports: 'التقارير',

                    // Side Nav Titles
                    sidenav_dashboards: 'الرئيسية',
                    sidenav_management: 'الإدارة',
                    sidenav_onlinestore: 'المتجر الإلكترونى',
                    sidenav_settings: 'الإعدادات',

                    nosecutitylayersfound: 'لا توجد طبقات حماية',

                    // Generall
                    showinfilter: 'الظهور فى الفلتر',
                    chosentags: 'العلامات المختارة',
                    tableformat: 'شكل الجدول',
                    cities: 'المدن',
                    state: 'المحافظة',
                    nostatusfound: 'لا توجد حالات',
                    totalusers: 'عدد المستخدمين',
                    from: 'من',
                    to: 'إلى',
                    filter: 'فلترة',
                    showfilter: 'إظهار الفلتر',
                    searchvendors: 'البحث عن موردين',
                    searchcollections: 'البحث عن مجموعات',
                    salestatus: 'حالة الخصم',
                    hassale: 'يوجد خصم',
                    nosale: 'بدون خصم',
                    all: 'الكل',
                    details: 'التفاصيل',
                    products: 'المنتجات',
                    chooseoption: 'اختر',
                    totalorders: 'إجمالى عدد الطلبات',
                    totalorders2: 'إجمالى الطلبات',
                    totalsales: 'إجمالى المبيعات',
                    lowstockproducts: 'منتجات وصلت للحد الادنى',
                    outofzone: 'خارج نطاق الشحن',
                    actiontype: 'نوع الفعل',
                    clearfilters: 'حذف التصفية',
                    online: 'Online',
                    apply: 'تطبيق',
                    remove: 'حذف',
                    notes: 'الملاحظات',
                    domain: 'المجال',
                    source: 'المصدر',
                    byyou: 'بواسطتك',
                    online: 'Online',
                    customer: 'عميل',
                    guest: 'زائر',
                    default: 'الاساسى',
                    choose: 'إختر',
                    done: 'تم',
                    image: 'الصورة',
                    deleteall: 'حذف الكل',
                    disclaimer: 'ملحوظة',
                    updatelogo: 'تحديث الشعار',
                    expirydate: 'تاريخ الإنتهاء',
                    institutes: 'المنشأت',
                    createdon: 'تاريخ الإنشاء',
                    mobile: 'الهاتف',
                    country: 'البلد',
                    address: 'العنوان',
                    send: 'إرسال',
                    select: 'اختر',
                    password: 'كلمة السر',
                    note: 'ملاحظة',
                    selectall: 'إختيار الكل',
                    unselectall: 'حذف الكل',
                    title: 'العنوان',
                    sale: 'خصم',
                    price: 'السعر',
                    saleprice: 'سعر الخصم',
                    onsale: 'تخفيض',
                    offsale: 'لا يوجد تخفيض',
                    languages: 'اللغات',
                    emailto: 'إرسال الى',
                    paymentdetails: 'تفاصيل الدفع',
                    archive: 'ارشفه',
                    branch: 'الفرع',
                    name: 'الإسم',
                    email: 'البريد الإلكترونى',
                    phonenumber: 'رقم التليفون',
                    edit: 'تعديل',
                    delete: 'حذف',
                    description: 'الشرح',
                    ifyespleaswrite: 'إذا كانت الإجابه نعم، من فضلك اكتب',
                    pleasewrite: 'من فضلك اكتب',
                    warning: 'تحذير',
                    search: 'البحث...',
                    total: 'الإجمالى',
                    date: 'التاريخ',
                    manage: 'إدارة',
                    egp: 'ج.م',
                    show: 'عرض',
                    notadded: 'لا يوجد',
                    back: 'رجوع',
                    save: 'حفظ',
                    submit: 'إرسال',
                    addtranslation: 'إضافة الترجمه',
                    removetranslation: 'إزالة الترجمة',
                    contactinformation: 'البيانات الشخصيه',
                    add: 'اضف',
                    arabictranslaion: 'ترجمة باللغة العربية',
                    englishtranslation: 'ترجمة باللغة الإنجليزية',
                    for: 'ل',
                    option: 'الإختيار',
                    options: 'الإختيارات',
                    done: 'تم',
                    hello: 'اهلا',
                    arabic: 'لغه عربيه',
                    english: 'لغه إنجليزيه',
                    livepreview: 'المعاينة الحية',
                    subscribe: 'الإشتراك',
                    logout: 'خروج',
                    language: 'اللغة',
                    allproducts: 'جميع المنتجات',
                    chosenproducts: 'المنتجات المختارة',
                    pleasecompletethemissingfields: 'من فضلك اكمل باقى البيانات',
                    product_s_: 'منتج(ات)',

                    // Status
                    securitygroup: 'مجموعة الحماية',
                    status: 'الحالة',
                    active: 'نشط',
                    draft: 'على الهامش',
                    notactive: 'غير نشط',
                    enable: 'تفعيل',
                    disable: 'تعطيل',
                    required: 'إجبارى',
                    optional: 'إختيارى',
                    visible: 'مرئى',
                    hidden: 'مخفى',
                    pending: 'منتظر',
                    waitingforpickup: 'منتظر حتى الإستلام',
                    pickedup: 'تم اخذه',

                    inputfield: 'Input Field',
                    selectbox: 'خانة الاختيارات',

                    // Welcome Page
                    manageyourstores: 'إدارة متاجرك',
                    createnewstore: 'إنشاء متجر جديد',
                    youhavenotcreatedanystoresyet: 'لم يتم إنشاء متجر بعد.',
                    yourstores: 'المتاجر:',
                    storename: 'إسم المتجر',
                    freetrial: 'تجربة مجانية',
                    dashboardhome: 'الصفحه الرئيسية',
                    upgrade: 'تطوير',
                    expired: 'منتهي الصلاحية',
                    type: 'النوع',
                    chooseoption: 'إختر',
                    currency: 'العملة',
                    createstore: 'إضافة المتجر',
                    noinstitutesyet: 'لا توجد منشأه بعد',
                    createnewinst: 'إضافة منشأه جديده',

                    // Shipping
                    updateshippingandaddressdetails: 'تحديث العنوان و بيانات الشحن',
                    shipping: 'الشحن',
                    chosenzones: 'المناطق المختارة',
                    addzone: 'إضافة منطقة جديدة',
                    zonename: 'إسم المنطقة',
                    zonerateprice: 'سعر الشحن',
                    zoneratesaleprice: 'سعر الشحن فى الخصم',
                    countries: 'البلاد',
                    editzone: 'تعديل بيانات المنطقة',
                    deletezone: 'حذف المنطقة',
                    youhavenotaddedanyzonesyet: 'لم يضاف اى مناطق بعد',
                    zonestates: 'المناطق',
                    shippingprice: 'سعر الشحن',
                    shippingsaleprice: 'سعر الشحن فى الخصم',
                    selectedzones: 'المناطق المختارة',
                    governorate: 'المحافظة',
                    city: 'المدينة',

                    // Couriers
                    zoneid: 'الرمز التعريفى للمنطقة',
                    zonename: 'إسم المنطقه',
                    zonecities: 'المناطق',
                    allcouriers: 'المندوبون',
                    addcourier: 'إضافة مندوب جديد',
                    couriername: 'إسم المندوب',
                    zones: 'المناطق',
                    addnewcourier: 'إضافة مندوب جديد',
                    editcourier: 'تعديل بيانات المندوب',
                    deletecourier: 'حذف المندوب',
                    nocouriersyet: 'لا يوجد مندوبين',

                    // Branches
                    addbranch: 'إضافة فرع جديد',
                    editbranch: 'تعديل الفرع',
                    deletebranch: 'حذف الفرع',
                    nobranchesyet: 'لا توجد فروع',
                    pleaseaddbranchname: 'من فضلك اضف اسم الفرع',

                    // Home
                    golivenow: 'اطلق متجرك الان!',
                    golivedesc: 'لا تفوت يوم اخر بدون الوجود على المنصة الإلكترونية!',
                    stepstocomplete: 'الخطوات المتبقية',
                    recentactivity: 'النشاطات الاخيرة',
                    homecard_createyourstore: 'انشأ متجرك',
                    homecard_addproducts: 'إضافة منتجات',
                    homecard_addcollections: 'إضافة مجموعات',
                    homecard_customizewebsite: 'تخصيص مظهر الموقع الإلكترونى',
                    homecard_addshippingdetails: 'إضافة تفاصيل الشحن',
                    homecard_golive: 'اطلق المتجر',
                    subscribenow: 'إشترك الان',

                    // Home2
                    sales: 'المبيعات',
                    totalsalestoday: 'جميع المبيعات لليوم',
                    profit: 'المكسب',
                    ratioperday: 'النسبة في اليوم',
                    totalorderstoday: 'جميع الطلبات لليوم',
                    visits: 'الزيارات',
                    totalvisitsfortoday: 'جميع الزيارات لليوم',
                    recentorders: 'الطلبات الحالية',
                    quickactions: 'إجرائات سريعة',
                    addcollection: 'إضافة مجموعة جديدة',
                    editcollection: 'تعديل المجموعة',

                    // Products
                    tagname: 'إسم العلامة',
                    addtag: 'اضف علامة للمنتج',
                    edittag: 'تحديث العلامة',
                    chooseproducttags: 'إخنر علامة المنتج',
                    productoptionmanagement: 'إدارة متغيرات المنتج',
                    measurementunit: 'وحدة التخزين',
                    editproductinformation: 'تعديل بيانات المنتج',
                    productinformation: 'بيانات المنتج',
                    productgallery: 'صور المنتج',
                    inventorysettings: 'إعدادات المخزن',

                    stockandpricing: 'المخزون و التسعير',
                    stockandinventory: 'المخزون و المخزن',
                    producttags: 'علامات المنتج',
                    importproducts: 'إدخال منتجات',
                    productstags: 'علامات المنتجات',
                    productslogs: 'سجلات المنتجات',
                    thresholdquantity: 'الحد الأدنى من الكمية',
                    assignproductoptions: 'إضافة الإختيارات',
                    assign: 'إضافة',
                    deassign: 'حذف',
                    assignvalues: 'إضافة المتغيرات',
                    doyouwanttodeletethisproduct: 'هل نريد حذف هذا المنتج؟',
                    addproducts: 'إضافة منتجات',
                    productvariants: 'متغيرات المنتج',
                    showproductvariants: 'عرض الخيارات',
                    noproductsyet: 'لا يوجد منتجات',
                    editproduct: 'تعديل المنتج',
                    addproduct: 'إضافة المنتج',
                    deleteproduct: 'حذف المنتج',
                    addnewproduct: 'إضافة منتج جديد',
                    filterbyavailability: 'التصفية حسب المنتجات المتاحة',
                    filterby: 'التصفية حسب',
                    unlisted: 'غير مصنفة',
                    productname: 'إسم المنتج',
                    collection: 'المجموعة',
                    inventorystatus: 'حالة المخزون',
                    limitedquantity: 'متوفر لفترة محدودة',
                    alwaysavailable: 'متوفر دائماً',
                    exportproductsasCSV: 'تصدير المنتجات على هيئة ملف CSV',
                    export: 'إصدار ',
                    exportproducts: 'إصدار المنتجات',
                    currentpage: 'الصفحة الحالية',
                    selectedproducts: 'المنتجات المُختارة',
                    filteredproducts: 'المنتجات المُفلترة',
                    productavailability: 'توافر المنتج',
                    changeproductavailability: 'تغيير حالة توافر المنتج',
                    doyouwantchangeprodavailto: 'هل تريد تغيير حالة توافر المنتج ل ',
                    productinformation: 'تفاصيل المنتج',
                    inventory: 'المخزن',
                    inventorysettings: 'إعدادات المخزن',
                    storagekeepingunit: 'وحدة التخزين',
                    sku: 'وحدة التخزين',
                    updateproductinformation: 'تعديل بيانات المنتج',
                    uploadproductimages: 'رفع صور المنتج',
                    productgallery: 'صور المنتج',
                    noimages: 'لا توجد صور',
                    defaultimagedisclaimer: 'إختر صورة إفتراضية للمنتج بالضغط على الصورة.',
                    updateinventory: 'تعديل المخزن',
                    updateinventorysettings: 'تعديل إعدادات المخزن',
                    minimumquantity: 'الحد الأدنى من الكمية',
                    defaultpricing: 'التسعير الاساسى',
                    optional_ifthereisanysale_: 'إختياري (في حالة وجود خصم)',
                    updatepricing: 'تعديل التسعير',
                    totalquantity: 'الكمبة الكلية',
                    orderedquantity: 'الكمية المطلوبة',
                    currentquantity: 'الكمية الحالية',
                    updatequantity: 'Update Quantity',
                    newquantity: 'الكمية الجديدة',
                    changetype: 'تغيير النوع',
                    updatequantity: 'تحديث الكمية',
                    product: 'المنتج',
                    // Options
                    variantsku: 'وحدة تخزين المتغير',
                    addoptionsdisclaimer: 'من فضلك اضف إختيارات المنتج',
                    productoptions: 'إختيارات المنتج',
                    addproductoptions: 'إضافة إختيارات المنتج',
                    editproductoptions: 'تعديل إختيارات المنتج',
                    addoption: 'إضافه الإختيار',
                    editoption: 'تعديل الإختيار',
                    optionname: 'إسم الإختيار',
                    optionvalue: 'المتغير',
                    optionvalues: 'المتغيرات',
                    productvariatname: 'إسم المتغير',
                    productvariatoptions: 'إختيارات المتغير',
                    pressentertosubmitvalues: 'اضغط Enter لإضافة الإختيارات',
                    waittillvalueisadded: 'من فضلك إنتظر حتى يتم إضافة الإختيارات',
                    pleaseaddoptionnameandvalues: 'من فضلك اضف الإختيارات و المتغيرات',
                    optionsaleprice: 'سعر الخصم للإختيار',
                    optionprice: 'سعر الإختيار',
                    // Variants
                    enablevariants: 'تفعيل الإختيارات',
                    disablevariants: 'تعطيل الإختيارات',
                    novariantsyet: 'لم يضاف اى متغيرات بعد',
                    choosevariantimage: 'إختر صوره المتغير',
                    variantimage: 'صوره المتغير',
                    variants: 'المتغيرات',
                    variants2: 'متغيرات',
                    variantpricing: 'تسعير المتغير',
                    variantprice: 'سعر المتغير',
                    variantsaleprice: 'سعر الخصم للمتغير',
                    updatevariant: 'تحديث المتغير',
                    addvariant: 'إضافة المتغير',
                    addproductvariants: 'إضافة متغيرات المنتج',
                    editproductvariants: 'تحديث متغيرات المنتج',
                    currentvariant: 'المتغير الحالى',

                    noproductimages: 'لا توجد صور للمنتج',
                    noproductimagesdisclaimer: 'من فضلا اضف صور للمنتج اولا',

                    // Collections
                    choosecollection: 'إختر المجموعة',
                    allcollections: 'جميع المجموعات',
                    collectiondescription: 'شرح المجموعة',
                    addnewcollection: 'إضافة مجموعة جديدة',
                    nocollectionsyet: 'لم يتم إضافة مجموعات بعد',
                    collectioninformation: 'بيانات المجموعة',
                    collectionname: 'إسم المجموعة',
                    collectionlogo: 'شعار المجموعة',
                    collectionproducts: 'منتجات المجموعة',
                    completecollectioninfo: 'من فضلك اكمل باقى بيانات المجموعة اولا',
                    uploadlogo: 'ارفع الشعار',
                    addcollection: 'إضافة المجموعة',
                    editcollection: 'تعديل المجموعة',
                    deletecollection: 'حذف المجموعة',
                    numberofproducts: 'عدد المنتجات',

                    // Orders & Customers
                    addressandshipping: 'العنوان و الشحن',
                    doyouwanttodeletecustomer: 'هل تريد حذف هذا العميل؟',
                    logs: 'السجلات',
                    updateorderpayment: 'تحديث طريقة الدفع',
                    orderitems: 'منتجات الطلب',
                    ordersummary: 'ملخص الطلب',
                    updateaddress: 'تحديث العنوان',
                    updatestatus: 'تحديث الحالة',
                    trackactivity: 'نشاط تتبع الطلب',
                    viewordersnotauth: 'عرض الطلبات ليس مسموحا',
                    noordersyet: 'لا يوجد طلبات',
                    resultsfromsearch: 'النتائج من البحث',
                    orderactivity: 'نشاط تتبع الطلب',
                    searchbyvendors: 'البحث عن طريق الموردين',
                    totalspent: 'إجمالى الصرف',
                    numberoforders: 'عدد الطلبات',
                    orderstatus: 'حالة الطلب',
                    chooseorderstatus: 'اختر حالة الطلب',
                    customerhavenoordersyet: 'لا يوجد طلبات',
                    noorderbythiscourier: 'لا يوجد طلبات من هذا المندوب',
                    previousorders: 'الطلبات السابقه',
                    paymentfulfillstatus: 'حالة الدفع و الإكتمال',
                    customerinformation: 'بيانات العميل',
                    shippingaddress: 'عنوان الشحن',
                    billingaddress: 'عنوان وصول الفواتير',
                    allorders: 'جميع الطلبات',
                    choosecustomer: 'إختر العميل',
                    customer: 'عميل',
                    guest: 'زائر',
                    chooseoptions: 'إختر',
                    havenotaddedproducts: 'لم يضاف منتجات بعد',
                    addquantity: 'اضف الكمية',
                    quantity: 'الكمية',
                    addneworder: 'إضافة طلب جديد',
                    addorder: 'إضافة الطلب',
                    filterbypaymentstatus: 'تصفيه حسب حالة الدفع',
                    filterbyfulfillmentstatus: 'التصفية حسب حالة إكتمال الطلب',
                    searchordernum: 'البحث عن طريق رقم الطلب',
                    customername: 'إسم العميل',
                    items: 'العناصر',
                    orderdetails: 'تفاصيل الطلب',
                    choosepaymentstatus: 'إختر حالة الدفع',
                    choosefulfillmentstatus: 'إختر حالة إكتمال الطلب',
                    fulfillorder: 'تكمله الطلب',
                    cancelfulfillment: 'إلغاء تكمله الطلب',
                    cancelfulfillmentconfirm: 'هل تريد إلغاء إكتمال هذا الطلب؟',
                    editfulfillmentstatus: 'تعديل حالة إكتمال الطلب',
                    dependonoption: 'على حسب الإختيار',
                    orderno: 'رقم الطلب',
                    ordernumber: 'رقم الطلب',
                    item: 'المنتج',
                    subtotal: 'المبلغ',
                    shippingrate: 'تكلفه الشحن',
                    discount: 'الخصم',
                    paidamount: 'المدفوع',
                    editorderstatus: 'تعديل حالة الطلب',
                    deleteorderstatus: 'حذف حالة الطلب',
                    addorderstatus: 'إضافه حالة للطلب',
                    titleinen: 'العنوان باللغه الإنجليزيه',
                    titleinar: 'العنوان باللغه العربيه',
                    bgcolor: 'لون الخلفية',
                    staff: 'للموظفبن',
                    customers: 'للعملاء',
                    forstaff: 'للموظفبن',

                    forcustomers: 'للعملاء',
                    additems: 'إضافة منتجات',
                    additem: 'إضافة منتج',
                    removeitem: 'حذف المنتج',
                    edititem: 'تعديل المنتج',
                    customernotes: 'ملحوظات العميل',
                    ordertrackingactivity: 'نشاط تتبع الطلب',
                    orderpaidstatusupdated: 'تم تعديل حالة الدفع',
                    deleteorder: 'حذف الطلب',
                    removecustomer: 'حذف العميل',
                    removecustomerconfirmation: 'هل تريد حذف هذا العميل؟',
                    numberoforders: 'الطلبات',
                    totalspending: 'الإنفاق الكلي',
                    youhavenotaddedanycustomersyet: 'لم يضاف أي عميل بعد',
                    addnewcustomer: 'إضافة عميل جديد',
                    editcustomer: 'تعديل بيانات العميل',
                    allcustomers: 'جميع العملاء',
                    deletecustomer: 'حذف العميل',
                    deletewarningtext: 'تحذير: سوف يتم حذف جميع بيانات العميل و الطلبات الخاصه به ',
                    showcustomerinfo: 'عرض بيانات العميل',
                    youhavenptaddedproductsinorder: 'لم يضاف منتجات فى الطلب بعد',
                    doyouwanttodelete: 'هل تريد حذف ',
                    fromordernum: 'من الطلب رقم',
                    order: 'الطلب',

                    // Promocodes
                    promocode: 'كود الخصم',
                    addpromocode: 'إضافة كود الخصم',
                    youhavenocouponsyet: 'لم يضاف اكواد خصم',
                    couponname: 'إسم الكود',
                    usage: 'الاستخدام',
                    // off: 'خصم',
                    doyouwanttodeletethiscoupon: 'هل تريد حذف كود الخصم؟',
                    editpromocode: 'تعديل الكود',
                    deletepromocode: 'حذف كود الخصم',

                    // Policies
                    privacypolicy: 'شروط الخصوصية',
                    privacypolicycontent: 'محتوى صفحه الشروط الخصوصيه',
                    termsandconditions: 'الشروط والأحكام',
                    refundpolicy: 'شروط الاسترجاع',
                    shippingpolicy: 'شروط الشحن',
                    policyinformation: 'تفاصيل الخصوصية',
                    deletepolicytranswarningtext: 'هل تريد حذف ترجمه؟',
                    generatetemplate: 'إنشاء نموذج',

                    // Institute Settings
                    storeinformation: 'بيانات المتجر',
                    storename: 'إسم المتجر',
                    storetype: 'نوع المتجر',
                    storeindustry: 'التخصص/المجال',
                    storelogo: 'شعار المتجر',
                    storefavouriteicon: 'الايقونه المفضلة للمتجر',
                    paymentsettings: 'إعدادات طرق الدفع',
                    updatecontactinformation: 'تعديل البيانات',
                    updatestoreinformation: 'تعديل بيانات المتجر',
                    updatestorelogo: 'تغيير شعار المتجر',
                    updatestorefavicon: 'تغيير الايقونه المفضله',
                    contactinfodesc: 'البيانات التى سوف يتواصل بها العملاء معك',
                    // Signup data
                    addnewdata: 'إضافة بيانات جديده',
                    editsignupdata: 'تعديل البيانات',
                    deletesignupdata: 'حذف البيانات',
                    signupcustomerinfo: 'بيانات تسجيل العميل',

                    // Payment
                    payment: 'الدفع',
                    deletepaymentmethod: 'حذف طريقه الدفع',
                    cod: 'الدفع عند الإستلام',
                    paymentmethod: 'طريقه الدفع',
                    paymentmethods: 'طرق الدفع',
                    onlinepaymentsettings: 'إعدادات الدفع الإلكترونى',
                    emailsettings: 'إعدادات البريد الإلكترونى',
                    choosepaymentmethod: 'إختر طريقه الدفع',
                    integrationinstructions: 'تعليمات الدمج',
                    paymenthassuccessfullydeleted: 'تم حذف طريقه الدفع',
                    doyouwanttodeletepaymentmethod: 'هل تريد حذف عملية الدفع ؟',
                    deletepayment: 'حذف عملية الدفع',
                    youhavenotaddedanypaymentmethodsyet: 'لم يضاف عمليات الدفع بعد',
                    checked: 'تم تحديدها',
                    notchecked: 'لن تحدد',
                    chosenpaymentmethods: 'إختيارات عمليات الدفع',
                    emailsettingscontractinformation: `يعني تحديد "نعم" أن عملائك لن يتلقوا تأكيدات بالبريد الإلكتروني للمعاملات المكتملة ، أو المستردة ، أو التي تم إلغاؤها ، حتى لو كانت المنصة قد مكنتهم على الصعيد العالمي. لا يزال بإمكان العملاء تلقي رسائل تأكيد بالبريد الإلكتروني من المزود. من المفيد تحديد "نعم" إذا أرسلت بالفعل تأكيدًا لمعاملة إلى عملائك.`,
                    usesandboxprovidercomment:
                        'إذا حددت استخدام وضع الحماية للموفر ، فسيتم إرسال جميع المدفوعات إلى مجال وضع الحماية لهذا الموفر ، وإذا لم يتم تحديدها ، فسيتم إرسال المدفوعات إلى مجال التشغيل المباشرة الخاصة بالموفر.',
                    choosecountry: 'إختر البلد',
                    // Fawry
                    fawrydescription: `تأكد من أن لديك حساب مدير Fawry خاص بك لتعيين عنوان الurl التالي كعنوان URL لإشعار الدفع (عنوان URL لمعاودة الاتصال):
                           <br>/https://moneyhash.io/providers/fawry/webhook`,
                    providedbyfawrypayteamduringtheaccountsetup: 'يقدمها فريق fawry pay أثناء إنشاء الحساب',
                    // Paymob
                    paymobinstructions: `تأكد من إضافة الروابط التاليه فى طرق دمج الدفع فى لوحه التحكم الخاصه ب Paymob Accept:
                             <br>https://moneyhash.io/providers/paymob/webhook/processed/ (in Transaction processed callback)
                             <br>https://moneyhash.io/providers/paymob/webhook/response/ (in Transaction response callback)`,
                    mobilewalletinputcomment: 'يجب إضافة +2 إلى رقم الهاتف ؛ مثال: +20011111111',

                    // Cow pay
                    cowpaycodeinputcomment: 'يقدمها فريق Cowpay أثناء إنشاء الحساب',
                    cowpaytokencomment: 'رمز المصادقة الخاص بالتدريج أو الإنتاج بناءً على مجال use_provider_sandbox',
                    cowpaycheckboxcomment:
                        'إذا تم تحديده ، فسيتم إرسال جميع المدفوعات إلى بيئة وضع الحماية الخاصة بهذا الموفر ، وإذا لم يتم تحديدها ، فسيتم إرسال المدفوعات إلى البيئة المباشرة للمزود.',
                    // Pay tabs
                    paytabsinputcomment: 'يتم الحصول عليها من لوحة معلومات الموفر',

                    // Analytics
                    connect: 'إتصال',
                    connectto: 'الإتصال ب',
                    disconnect: 'قطع الإتصال',
                    id: 'الرمز التعريفي ل',
                    id2: 'الرمز التعريفي',
                    allowsyoutotrackwebsiteperformanceandcollectvisitorinsights: 'يسمح لك بتعقب أداء الموقع وجمع الزيارات أو الأفكار. ',
                    allowsyoutomeasureyouradvertisingROIandtrackyoursocialnetworkingsitesandapplications: 'يسمح لك بقياس عائد الاستثمار الإعلاني وتتبع مواقع الشبكات الاجتماعية والتطبيقات الخاصة بك.',
                    allowsyoutomeasuretheeffectivenessofyouradvertising: 'يسمح لك بقياس فعالية الإعلان الخاص بك.',

                    // General Settings
                    userinformation: 'البيانات الشخصية',
                    changeemail: 'تغيير البريد الإلكتروني',
                    subscriptiondetails: 'معلومات عن الإشتراك',
                    currentemail: 'البريد الحالى',
                    newemail: 'البريد الإلكتروني الحديث',
                    oldpassword: 'كلمة السر الحالية',
                    newpassword: 'كلمة السر الحديثة',
                    errorinchangingemail: 'خطأ في تجديد البريد الإلكتروني',
                    errorinchangingpassword: 'خطأ في تجديد كلمة السر',
                    serveremailchangeerror: 'خطأ فى تجديد البريد الإلكتروني،  بالرجاء الإتصال بفريق Tejarrtech.',
                    serverpasswordchangeerror: 'خطأ فى تجديد كلمة السر،  بالرجاء الإتصال بفريق Tejarrtech.',
                    upgradeuserinformation: 'تحديث البيانلت الشخصية',
                    errorinaddinginformation: 'خطأ في إضافة المعلومات',
                    errorineditinginformation: 'خطأ في تعديل المعلومات',
                    dataupadated: 'تم تعديل البيانات',
                    servererrorinaddinginformation: 'خطأ فى إضافة المعلومات،  بالرجاء الإتصال بفريق Tejarrtech.',
                    servererrorineditinginformation: 'خطأ فى تعديل المعلومات،  بالرجاء الإتصال بفريق Tejarrtech.',
                    sendverificationcode: 'إرسال رمز التحقق',
                    verificationcoderequested: 'تم طلب رمز التحقق',
                    codeverified: 'تم تأكيد رمز التحقق',
                    youremailhaschangesuccessfully: 'تم تغيير البريد الإلكتروني',
                    pleaseinserttheverificationcode: 'برجاء إدخال رمز التحقق',
                    verificationcode: 'إدخال رمز التحقق',
                    packagename: 'اسم الباقه',
                    duration: 'المدة',
                    fees: 'الرسوم',
                    annualfees: 'رسوم سنوية',
                    maximuminstitutes: 'الحد الأقصى من المنشات',
                    mobileapplication: 'تطبيق للهاتف',
                    emailalreadyexists: 'هذا البريد غير مختلف',
                    changepassword: 'تغيير كلمة السر',
                    passwordalreadyexists: 'كلمة السر غير مختلفة',
                    oldpasswordisincorrect: 'كلمة سر غير صحيحة',
                    updateuserinformation: 'تحديث البيانات',

                    // Uploader
                    chosenfiles: 'الملفات المختارة',
                    uploadfiles: 'رفع الملفات',
                    uploaderdropdowntext: 'اختر او اسحب الملفات او اضغط للتصفح',

                    // Mobile Application
                    notificationslogs: 'سجلات التنبيهات',
                    mobileapplicationusers: 'مستخدمين تطبيق الهاتف',
                    loggedinusers: 'المستخدمين الحاليين',
                    alldevices: 'جميع الاجهزه',
                    notificationid: 'رمز التنبيه',
                    banned: 'محذور',
                    loggedin: 'مسجل',
                    lastseen: 'اخر ظهور',
                    loggedindevicetype: 'الجهاز المسجل',
                    mob_deviceid: 'الرمز التعريفى للجهاز',
                    notificationallowed: 'السماح بالتنبيهات',
                    sendnotification: 'إرسال تنبيه',
                    banemail: 'حظر البريد الإلكترونى',
                    notificationcontent: 'محتوى التنبيه',
                    notificationtitle: 'عنوان التنبيه',
                    sendnotificationto: 'إرسال التنبيه الى',
                    notloggedin: 'غير مسجل',
                    notallowed: 'غير مسموح',
                    allowed: 'مسموح',
                    website: 'الموقع الإلكترونى',

                    // Users
                    adduser: 'إضافة مستخدم جديد',
                    edituser: 'تعديل بيانات المستخدم',
                    deleteuser: 'حذف المستخدم',
                    youhavenousersyet: 'لا يوجد مستخدمين',

                    // SMS SERVICE
                    smsserviceinformation: 'بيانات خدمه الرسائل',
                    senderid: 'Sender ID',
                    addsenderid: 'إضافة Sender ID',
                    thissenderid: 'this sender ID',
                    deletesenderid: 'حذف Sender ID',
                    sendername: 'اسم المرسل',
                    senderstatus: 'الحالة',
                    taxpaper: 'Tax Paper',
                    instpaper: 'Inst Paper',
                    balance: 'الرصيد',
                    requestbalance: 'طلب رصيد',
                    numberofmsgs: 'عدد الرسائل',
                    smslogs: 'سجلات الرسائل',
                    logid: 'Log ID',
                    smsstatus: 'حالة الرساله',
                    reason: 'السبب',
                    smscontent: 'محتوى الرساله',
                    requestedbalance: 'الرصيد المطلوب',
                    noofmsgs: 'عدد الرسائل',
                    balancelogs: 'سجلات الرصيد',
                    balancelogid: 'Balance Log ID',
                    nosmsrequested: 'عدد الرسائل المطلوبة',
                    addsernderid: 'إضافة Sender ID',
                    addbalance: 'إذافة رصيد',
                    sendmessage: 'إرسال رسالة',
                    smsbody: 'محتوى الرسالة',
                    receiverphonenumber: 'رقم هاتف المستقبل',

                    // Vendors
                    allvendors: 'جميع الموردين',
                    vendorname: 'إسم المورد',
                    location: 'المكان',
                    addvendor: 'إضافة مورد',
                    deletevendor: 'حذف مورد',
                    editvendorinfo: 'تعديل بيانات المورد',
                    doyouwanttodeletethisvendor: 'هل تريد حذف هذا المورد؟',

                    // Categories
                    nocollections: 'لا توجد مجموعات',
                    categories: 'الفئات',
                    addcategory: 'إضافة فئة',
                    deletecategory: 'حذف الفئة',
                    editcategory: 'تعديل الفئة',
                    categoryname: 'إسم الفئة',
                    collections: 'المجموعات',
                    visibilitystatus: 'حالة الظهور',
                    visibleinfilter: 'حالة الظهور فى الفلتر',
                    categorylogo: 'شعار الفئة',
                    categoryinfo: 'بيانات الفئة',
                    categorydescription: 'الشرح',
                    categorystatus: 'حالة الفئة',
                    visibleinfilter: 'الظهور فى الفلتر',
                    visibletocustomers: 'الظهور للعملاء',
                    categorycollections: 'مجموعات الفئة',
                    chosencollections: 'المجموعات المختارة',

                    // Notifications
                    servererrorupdatevendors: 'خطأ فى تحديث الموردين. برجاء الإتصال بفريق Tejarrtech',
                    vendorsupdatesuccess: 'تم تحديث الموردين',
                    servererrorupdatesecuritygp: 'خطأ فى تحديث مستوى الحماية. برجاء الإتصال بفريق Tejarrtech',
                    securitygpupdatedsuccess: 'تم تحديث مستوى الحماية',
                    // Products Notifications
                    optionsupdatessuccess: 'تم تحديث الإختيارات',
                    servererrorupdateoptions: 'خطأ فى تحديث المتغيرات برجاء الإتصال بفريق Tejarrtech',
                    valuealreadyexists: 'النتغير موجود بالفعل',
                    pleasewritevaluename: 'من فضلك اكتب إسم المتغير',
                    pleasewritevalues: 'من فضلك اكتب المتغيرات',
                    pleasewriteoptionname: 'من فضلك اكتب إسم الإختيار',
                    productaddedsuccess: 'تم إضافة المنتج',
                    servererroraddingproduct: 'خطأ فى إضافة المنتج، برجاء الإتصال بفريق Tejarrtech',
                    optionsupdatedsuccess: 'تم تغيير إختيارات المنتج',
                    servererroroptionsupdated: 'خطأ فى تحديث إختيارات المنتج، برجاء الإتصال بفريق Tejarrtech',
                    deleteproductsuccess: 'تم حذف المنتج',
                    servererrordeleteproduct: 'خطأ فى حذف المنتج، برجاء الإتصال بفريق Tejarrtech',
                    errorupdateproduct: 'خطأ فى تحديث المنتج، برجاء الإتصال بفريق Tejarrtech',
                    // Images
                    imageuploadedsuccess: 'تم رفع الصور(ة) بنجاح',
                    imagedeletedsuccess: 'تم حذف الصورة',
                    servererroruploadingimage: 'خطأ فى رفع الصور(ة). برجاء الإتصال بفريق Tejarrtech',
                    servererrordeleteimage: 'خطأ فى حذف الصورة. برجاء الإتصال بفريق Tejarrtech',
                    // Product Images
                    galleryimagedeletedsuccess: 'تم حذف الصورة',
                    servererrordeletegalleryimage: 'خطأ فى حذف الصورة. برجاء الإتصال بفريق Tejarrtech',
                    defaultimageupdatedsuccess: 'تم تحديث الصورة الاساسية',
                    servererrorupdatedefaultimage: 'خطأ فى تحديث الصورة الاساسية. برجاء الإتصال بفريق Tejarrtech',
                    // Quantity
                    erroraddingquantity: 'خطأ فى إضافة الكمية. برجاء الإتصال بفريق Tejarrtech',
                    // Prod Info
                    prodinfoupdatessuccess: 'تم تعديل بيانات المنتج',
                    servererrorupdateprodinfo: 'خطأ فى تعديل بيانات المنتج. برجاء الإتصال بفريق Tejarrtech',
                    // Choose Collection
                    pleasechoosecollection: 'من فضلك اختر مجموعة',
                    servererrorchangeprodoptionvalues: 'Error in Changing Product Options Values. Please Contact Tejarrtech Team',
                    // Prod Avail
                    productavailupdatesuccess: 'تم تغيير حالة توافر المنتج',
                    servererrorupdateprodavail: 'خطأ فى تغيير حالة توافر المنتج. برجاء الإتصال بفريق Tejarrtech',
                    // Inventory
                    inventoryupdateduccess: 'تم تحديث المخزن',
                    servererrorupdateinventory: 'خطأ فى تحديث المخزن. برجاء الإتصال بفريق Tejarrtech',
                    // Variants
                    statuschangessuccess: 'تم تغيير الحالة',
                    servererrorchangevariantsstatus: 'خطأ فى تغيير الحالة. برجاء الإتصال بفريق Tejarrtech',
                    variantimageupdated: 'تم تحديث صورة المتغير',
                    variantupdatedsuccess: 'تم تحديث بيانات المتغير',
                    servererrorupdatevariant: 'خطأ فى تحديث بيانات المتغير. برجاء الإتصال بفريق Tejarrtech',
                    variantaddedsuccess: 'تم إضافة المتغير',
                    servererroraddvariant: 'خطأ فى إضافة بيانات المتغير. برجاء الإتصال بفريق Tejarrtech',
                    variantdeletedsuccess: 'تم حذف المتغير',
                    servererrordeletevariant: 'خطأ فى حذف المتغير. برجاء الإتصال بفريق Tejarrtech',
                    // Pricing
                    defaultpricingupdatedsuccess: 'تم تحديث السعر',
                    servererrorupdatedefaultpricing: 'خطأ فى تحديث السعر الاساسى. برجاء الإتصال بفريق Tejarrtech',
                    translationsuccessfullysaved: 'تم حفظ الترجمة',
                    policiessavedsuccessfully: 'تم حفظ البيانات بنجاح',
                    servererrorsavepolicy: 'خطأ فى حفظ البيانات، بالرجاء الإتصال بفريق Tejarrtech',
                    // Orders Notifications
                    shippingzonefound: 'تم العثور على منطقة الشحن',
                    promocoderemoved: 'تم حذف كود الخصم',
                    servererrorcheckingzones: 'خطأ فى العثور على منطقة الشحن. الإتصال بفريق Tejarrtech',
                    promocodeapplied: 'تم تطبيق كود الخصم',
                    servererrorapplyingpromocode: 'خطأ فى تطبيق كود الخصم. الإتصال بفريق Tejarrtech',
                    pleasewriteyourpromocode: 'من فضلك ادخل كود الخصم',
                    orderisaddedsuccess: 'تم إضافة الطلب بنجاح',
                    servererroraddingorder: 'خطأ فى إضافة الطلب بالرجاء الإتصال بفريق Tejarrtech',
                    pleaseaddproducts: 'من فضلك اضف منتجات اولا',
                    pleasechoosefulfillmentstatus: 'من فضلك اختر حالة إكتمال الطلب',
                    pleasechoosepaidstatus: 'من فضلك اختر حالة الدفع',
                    pleasechooseorderstatus: 'من فضلك اختر حالة الطلب',
                    itemisaddedsuccess: 'تم إضافة المنتج',
                    itemisdeletedsuccess: 'تم حذف المنتج',
                    orderisdeletedsuccess: 'تم حذف الطلب',
                    statusiseditedsuccess: 'تم تغيير حالة الطلب بنجاح',
                    servererrordeleteorder: 'خطأ فى حذف الطلب. برجاء الإتصال بفريق Tejarrtech',
                    servererrorremoveitem: 'خطأ فى حذف المنتج. برجاء الإتصال بفريق Tejarrtech',
                    quantityisadded: 'تم إضافة الكمية',
                    completestepstoplaceorder: 'اكمل باقى الخطوات لتكمله الطلب',
                    servererroreditstatus: 'خطأ فى تغيير حالة الطلب. برجاء الإتصال بفريق Tejarrtech',
                    pleaseaddquantity: 'من فضلك اضف الكمية',
                    servererrorsearchorders: 'خطأ فى البحث عن الطلب. برجاء الإتصال بفريق Tejarrtech',
                    servererrorupdatingorders: 'خطأ فى تعديل الطلب. برجاء الإتصال بفريق Tejarrtech',
                    orderupdatedsuccess: 'تم تحديث الطلب',
                    servererrorsearchvendors: 'خطأ فى البحث عن المورد. برجاء الإتصال بفريق Tejarrtech',
                    choosecountrystatecity: 'من فضلك إختر البلد و المحافظة و المدينة',
                    doyouwanttodeleteorder: 'هل تريد حذف الطلب',
                    servererroreditingorderstatus: 'خطأ فى تحديث حالة الطلب. برجاء الإتصال بفريق Tejarrtech',
                    orderstatusupdatedsuccess: 'تم تحديث حالة الطلب',
                    // Collections Notifications
                    collectionaddedsuccessfully: 'تم إضافة المجموعة',
                    collectiondeletedsuccessfully: 'تم حذف المجموعة',
                    collectioneditedsuccessfully: 'تم تعديل المجموعة',
                    collectionlogoupdatesucess: 'تم تغيير شعار المجموعة',
                    servererroraddingcollection: 'خطأ فى إضافة مجموعة جديده.  برجاء الإتصال بفريق Tejarrtech',
                    servererroreditingcollection: 'خطأ فى تعديل المجموعة.  برجاء الإتصال بفريق Tejarrtech',
                    servererrorupdatecollectionlogo: 'خطأ فى تغيير شعار المجموعة.  برجاء الإتصال بفريق Tejarrtech',
                    itemisdeletedfromcollection: 'تم حذف المنتج من المجموعة',
                    itemisaddedtocollection: 'تم إضافه المنتج فى المجموعة',
                    // Customers Notifications
                    addcustomerservererror: 'خطأ فى إضافة عميل جديد، برجاء الإتصال بفريق Tejarrtech',
                    editcustomerservererror: 'خطأ فى تعديل العميل، برجاء الإتصال بفريق Tejarrtech',
                    deletecustomerservererror: 'خطأ فى حذف العميل، برجاء الإتصال بفريق Tejarrtech',
                    customerhasbeensuccessfullyadded: 'تم إضافة العميل',
                    customerhasbeensuccessfullyedited: 'تم تعديل بيانات العميل',
                    customerhasbeensuccessfullydeleted: 'تم حذف العميل',
                    pleaseaddname: 'يرجى إضافة إسم',
                    pleaseselectbranch: 'من فضلك اختر فرع',
                    // Shipping Notifications
                    servererroraddingzone: 'خطأ فى إضافة منطقه جديده. برجاء الإتصال بفريق Tejarrtech',
                    servererroreditingzone: 'خطأ فى تعديل بيانات المنطقه. برجاء الإتصال بفريق Tejarrtech',
                    servererrordeletingzone: 'خطأ فى حذف المنطقه. برجاء الإتصال بفريق Tejarrtech',
                    zoneeditedsuccess: 'تم تعديل بيانات المنطقه بنجاح',
                    zoneaddedsuccess: 'تم إضافة المنطقه بنجاح',
                    zonedeletedsuccess: 'تم حذف المنطقه بنجاح',
                    allstatesselected: 'تم إختيار جميع المناطق',
                    allstatesunselected: 'تم حذف جميع المناطق',
                    // Branches Notifications
                    brancheditedsuccess: 'تم تعديل بيانات الفرع',
                    branchaddessuccess: 'تم إضافة الغرع',
                    branchdeleted: 'تم حذف الفرع',
                    errorinaddingbranchserver: 'خطأ فى إضافة فرع جديد. برجاء الإتصال بفريق Tejarrtech',
                    erroreditingbranchserver: 'خطأ فى تعديل بيانات الفرع. برجاء الإتصال بفريق Tejarrtech',
                    errordeletingbranchserver: 'خطأ فى حذف الفرع. برجاء الإتصال بفريق Tejarrtech',
                    // Institute Settings Notifications
                    pleasecomleteyourcontactinformation: 'من فضلك اكمل باقى البيانات الشخصيه',
                    contactinfoupdatedsuccess: 'تم تعديل البيانات الشخصيه',
                    servererrorcontactinfo: 'خطأ فى تعديل البيانات الشخصيه. برجاء الإتصال بفريق Tejarrtech',
                    storeinfoupdatedsuccess: 'تم تعديل بيانات المتجر',
                    servererrorstoreinfo: 'خطأ فى تعديل بيانات المتجر. برجاء الإتصال بفريق Tejarrtech',
                    logoaddedsuccess: 'تم إضافة الشعار',
                    erroruploadingfavicon: 'خطأ فى تغيير الايقونه المفضله. برجاء الإتصال بفريق Tejarrtech',
                    faviconupdatedsuccess: 'تم تغيير الايقونه المفضله',
                    erroruploadinglogo: 'خطأ فى تغيير شعار المتجر. برجاء الإتصال بفريق Tejarrtech',
                    pleaseuploadimage: 'من فضلك قم برفع صوره اولا',
                    pleasecompletecompletestoreinfo: 'من فضلك اكمل باقى بيانات المتجر',
                    // Signup data
                    infoaddedsuccess: 'تم الإضافة بنجاح',
                    infoeditedsuccess: 'تم التعديل بنجاح',
                    infoeletedsuccess: 'تم الحذف بنجاح',
                    // Payment Notifications
                    paymentmethodadded: 'تم إضافة عملية الدفع',
                    paymentmethodenabled: 'تم تفعيل عملية الدفع',
                    paymentmethoddisabled: 'تم تعطيل عملية الدفع',
                    servererroraddpaymentmethod: 'خطأ فى إضافة طريقه دفع جديده. برجاء الإتصال بفريق Tejarrtech',
                    servererrordeletepaymentmethod: 'خطأ فى إضافة حذف طريقه الدفع. برجاء الإتصال بفريق Tejarrtech',
                    servererrorchangeactivationpaymentmethod: 'خطأ فى إضافة تغيير الحالة. برجاء الإتصال بفريق Tejarrtech',
                    // Coupons Notifications
                    serveraddpromocodeerror: 'خطأ فى إضافة كود الخصم،  برجاء الإتصال بفريق Tejarrtech.',
                    servereditpromocodeerror: 'خطأ فى تعديل كود الخصم،  برجاء الإتصال بفريق Tejarrtech.',
                    serverdeletepromocodeerror: 'خطأ فى حذف كود الخصم،  برجاء الإتصال بفريق Tejarrtech.',
                    couponaddedsuccess: 'تم إضافة كود الخضم',
                    couponeditedsuccess: 'تم تعديل كود الخصم',
                    coupondeletedsucess: 'تم حذف كود الخصم',
                    // Users Notifications
                    servererroraddinguser: 'خطأ فى إضافة مستخدم جديد. برجاء الإتصال بفريق Tejarrtech',
                    servererroreditinguser: 'خطأ فى تعديل بيانات المستخدم. برجاء الإتصال بفريق Tejarrtech',
                    servererrordeletinguser: 'خطأ فى حذف المستخدم. برجاء الإتصال بفريق Tejarrtech',
                    usereditedsuccess: 'تم تعديل بيانات المستخدم بنجاح',
                    useraddedsuccess: 'تم إضافة المستخدم بنجاح',
                    userdeletedsuccess: 'تم حذف المستخدم بنجاح',
                    // SMS Service Notifications
                    senderidaddedsuccess: 'تم إضافة Sender ID',
                    senderiddeletedsuccess: 'تم حذف Sender ID',
                    servererroraddsenderid: 'خطأ فى إضافة Sender ID. برجاء الإتصال بفريق Tejarrtech',
                    servererrordeletesenderid: 'خطأ فى حذف Sender ID. برجاء الإتصال بفريق Tejarrtech',
                    servererrorsendsms: 'خطأ فى إرسال الرساله. برجاء الإتصال بفريق Tejarrtech',
                    sendsmssentsuccess: 'تم إرسال الرساله',
                    // Mobile Application Notifications
                    notificationsentsucceess: 'تم إرسال التنبيه',
                    servererrorsendnotification: 'خطأ فى إرسال التنبيه. برجاء الإتصال بفريق Tejarrtech',
                    allusersselected: 'تم إختيار جميع العملاء',
                    // Couriers Notifications
                    servererrorupdatecouriers: 'خطأ فى التحديث. برجاء الإتصال بفريق Tejarrtech',
                    couriersupdatedsuccessfully: 'تم التحديث',
                    zonealreadyexists: 'المنطقة توجد بالفعل',
                    deletecourierconfirmation: 'هل تريد حذف المندوب؟',
                    // Categories Notifications
                    categoriesupdatedsuccess: 'تم تحديث الفئات',
                    servererrorerrorupdatingcategories: 'خطأ فى تحديث الفئات. برجاء الإتصال بفريق Tejarrtech',
                    categoryaddedsuccess: 'تم إضافة الفئة',
                    servererrorerroraddingcategory: 'خطأ فى إضافة الفئة. برجاء الإتصال بفريق Tejarrtech',
                    errordeletecategory: 'خطأ فى حذف الفئة. برجاء الإتصال بفريق Tejarrtech',

                    // Paid and fulfilment Status
                    itemfulfillmentstatus: 'حالة إكتمال المنتج',
                    fulfillmentstatus: 'حالة إكتمال الطلب',
                    paidstatus: 'حالة الدفع',
                    paid: 'مدفوع',
                    unpaid: 'غير مدفوع',
                    canceled: 'ملغي',
                    fulfilled: 'مكتمل',
                    unfulfilled: 'غير مكتمل',
                    partiallyfulfilled: 'مكتمل جزئياً',
                    enabled: 'مفعل',
                    disabled: 'غير مفعل',
                },
            },
            langdetect: '',
            setlang: this.setlang,
        };
    }

    setlang = (key) => {
        const cookies = new Cookies();
        cookies.set('sitelang', key, { path: '/' });
        this.setState({
            langdetect: key,
        });
    };

    componentDidMount() {
        const cookies = new Cookies();
        var lastlangselected = cookies.get('sitelang');
        if (lastlangselected == 'en') {
            this.setState({
                langdetect: 'en',
            });
        } else if (lastlangselected == 'ar') {
            this.setState({
                langdetect: 'ar',
            });
        } else {
            this.setState({
                langdetect: 'en',
            });
        }
    }

    render() {
        var setlang = this.state.setlang;
        var lang = '';

        if (this.state.langdetect == 'en') {
            lang = this.state.lang.en;
            document.body.setAttribute('dir', 'ltr');
        } else if (this.state.langdetect == 'ar') {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        }
        var langdetect = this.state.langdetect;
        return <LanguageContext.Provider value={{ lang, setlang, langdetect }}>{this.props.children}</LanguageContext.Provider>;
    }
}

export const LanguageContextConsumer = LanguageContext.Consumer;
